import React from 'react';

import CN from 'clsx';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  Container,
  PseudoLink,
  Text,
} from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';

import styles from './error-message.module.scss';
export interface IErrorMessageProps {
  title?: string;
  subTitle?: string;
  hideReload?: boolean;
  compact?: boolean;
  errorCodeMsg?: string;
  userMessage?: string;
  videoUrl?: string;
  children?: React.ReactNode;
}

export const ErrorMessage = ({
  title = 'Oops! We made a mistake.',
  userMessage = "Apologies for the inconvenience. We've been notified of this error and are working to fix it.",
  subTitle,
  hideReload = false,
  videoUrl,
  compact,
  children,
}: IErrorMessageProps) => {
  const router = useRouter();

  return (
    <Container className={CN({ [styles.compact]: compact })}>
      <Box
        className={styles.errorMessageBox}
        m={Box.top('64px')}
        textAlign="center"
        color="red"
      >
        {videoUrl && (
          <iframe
            title="Error Video"
            allowFullScreen
            frameBorder={0}
            height={315}
            width={560}
            className={styles.video}
            src={videoUrl}
          />
        )}
        <Text type="h2" m="16px 0">
          {title}
        </Text>
        {!!subTitle && (
          <Text m="16px 0" type="bodyMedium" color="gray70">
            {subTitle}
          </Text>
        )}
        <Text type="bodyMedium" light>
          {userMessage}
        </Text>
        {!hideReload && (
          <Text type="bodyMedium" light>
            Please, try to{' '}
            <PseudoLink onClick={() => router.reload()}>reload</PseudoLink> page
            and repeat the action.
          </Text>
        )}
        {!children && (
          <Text type="bodyMedium" light m="32px 0 0">
            If this does not help, in the meantime, you can:
          </Text>
        )}
        <Flex m="24px 0 0" justifyContent="center" direction={{ sm: 'column' }}>
          {children ?? (
            <>
              <Button responsive href="/companies" className={styles.button}>
                Explore startups
              </Button>
              <Button responsive href="/issuer/learn" className={styles.button}>
                Raise money
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </Container>
  );
};
