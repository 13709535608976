import { DefaultOptions, NormalizedCacheObject } from '@apollo/client';

import { TAuthPersistence } from '@r-client/shared/util/core';

import { IRefreshTokenMutation } from '../graphql/types';

export type TReporting = {
  error(...args: (string | Record<string, unknown> | Error)[]): void;
  debug(...args: (string | Record<string, unknown> | Error)[]): void;
};

export interface IGqlClientConfig {
  apiAuthHeader?: string;
  apiUri: string;

  // These fields needed for reporting to Apollo Studio
  // about current API clients and their versions
  clientName: string;
  clientVersion: string;

  authPersistence?: TAuthPersistence;
  defaultOptions?: DefaultOptions;
}

export interface IClientOpts extends IGqlClientConfig {
  initialState?: NormalizedCacheObject;
  queryBatchingEnabled?: boolean;
  reporting?: TReporting;
}

export enum E_REFRESH_TOKEN_STATUS {
  Success,
  Error,
  NetworkError,
}

export type TRefreshTokenResult =
  | {
      status:
        | E_REFRESH_TOKEN_STATUS.Error
        | E_REFRESH_TOKEN_STATUS.NetworkError;
      error: Error;
    }
  | {
      status: E_REFRESH_TOKEN_STATUS.Success;
      token: NonNullable<IRefreshTokenMutation['Jwt_refreshToken']['token']>;
    };
