import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';

import { isMSWEnabled, MSW_POSSIBLE } from '@r-client/shared/data/flags/local';
import { TAuthPersistence } from '@r-client/shared/util/core';

import { createBatchKeyBuilder } from './config/batch-config';
import { CacheConfig } from './config/cache-config';
import { createAuthCookieLink } from './link/auth-cookie';
import { createAuthLocalStorageLink } from './link/auth-local-storage';
import { createInfraAuth } from './link/infra-header';
import { IClientOpts, IGqlClientConfig, TReporting } from './types';

function createApolloClient({
  initialState,
  queryBatchingEnabled = true,
  apiAuthHeader,
  apiUri,
  clientName,
  clientVersion,
  reporting,
  authPersistence,
  defaultOptions,
}: IClientOpts) {
  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: 3,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => {
        const doNotTryCodes = [400];
        return !!error && !doNotTryCodes.includes(error.statusCode);
      },
    },
  });

  const clientNameLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'apollographql-client-name': clientName,
        'apollographql-client-version': clientVersion,
      },
    };
  });

  const authLink = (function chooseAuthLink(variant?: TAuthPersistence) {
    if (variant?.type === 'localStorage')
      return createAuthLocalStorageLink({ apiUri, apiAuthHeader, reporting });
    if (variant?.type === 'cookie') {
      return createAuthCookieLink({
        apiUri,
        apiAuthHeader,
        reporting,
        authCookiePrefix: variant.cookiePrefix,
      });
    }
    return undefined;
  })(authPersistence);

  const HttpLinkImpl = queryBatchingEnabled ? BatchHttpLink : HttpLink;

  const httpLink = new HttpLinkImpl({
    uri: apiUri,
    batchKey: createBatchKeyBuilder(apiUri),

    ...(authPersistence?.type === 'cookie' ? { credentials: 'include' } : {}),
  });

  const cache = new InMemoryCache(CacheConfig).restore(initialState || {});

  const linkChain = [createInfraAuth(apiAuthHeader), clientNameLink];
  if (authLink) {
    linkChain.push(authLink);
  }
  return new ApolloClient({
    link: ApolloLink.from([...linkChain, retryLink, httpLink]),
    ssrMode: typeof window === 'undefined',
    cache,
    defaultOptions,
  });
}

// MSW does not support it

const queryBatchingEnabled = !(MSW_POSSIBLE && isMSWEnabled());

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function createGqlClient({
  initialState = undefined,
  config,
  reporting,
}: {
  initialState?: NormalizedCacheObject;
  config: IGqlClientConfig;
  reporting?: TReporting;
}) {
  const _apolloClient =
    apolloClient ??
    createApolloClient({
      queryBatchingEnabled,
      reporting,
      ...config,
    });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = mergeWith(
      existingCache,
      initialState,
      // combine arrays using object equality (like in sets)
      (destinationArray: unknown[], sourceArray: unknown[]) => {
        if (isArray(destinationArray) && isArray(sourceArray)) {
          return [
            ...sourceArray,
            ...destinationArray.filter((d) =>
              sourceArray.every((s) => !isEqual(d, s))
            ),
          ];
        }
      }
    );

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export type GraphqlClient = ReturnType<typeof createApolloClient>;
