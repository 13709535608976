/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGetFeatureToggleQuery,
  IGetFeatureToggleQueryVariables,
  GetFeatureToggleDocument,
} from './types';
import { IFlipper } from '@r-client/data/graphql-types';
export type { IFlipper };

//export interface IGetFeatureToggleQueryOpts extends Omit<IQueryModelOpts<IGetFeatureToggleQueryVariables>, 'variables'> {
export interface IGetFeatureToggleQueryOpts
  extends IQueryModelOpts<IGetFeatureToggleQueryVariables> {
  variables: IGetFeatureToggleQueryVariables;
}

export class GetFeatureToggleQuery extends BaseQueryModel<
  IGetFeatureToggleQuery,
  IGetFeatureToggleQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGetFeatureToggleQueryOpts) {
    super(opts);
  }

  get query() {
    return GetFeatureToggleDocument;
  }
}
export function useGetFeatureToggleQuery(
  opts: Omit<IGetFeatureToggleQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new GetFeatureToggleQuery({ ...opts, client }),
    opts?.variables
  );
}
