import { GraphQLError } from 'graphql/error';

import { ICodeEnum, IValidationError } from '@r-client/data/graphql-types';

const isValidationError = (
  gqlError: GraphQLError & { __typename?: IValidationError['__typename'] }
) => {
  return !!(gqlError.__typename && gqlError.__typename === 'ValidationError');
};

export const toValidationErrors = (
  gqlErrors: readonly GraphQLError[] = []
): Partial<IValidationError>[] => {
  return gqlErrors.filter(
    isValidationError
  ) as unknown[] as Partial<IValidationError>[];
};

const isUserInputError = (error: Partial<IValidationError>) =>
  error.extensions && error.extensions.code === ICodeEnum.BadUserInput;

export const getUserInputErrors = (errors: Partial<IValidationError>[]) => {
  return errors.reduce((record: { [x: string]: string | undefined }, error) => {
    if (isUserInputError(error)) {
      const field = error.extensions?.field;
      if (field) {
        record[field] = error.message;
      }
    }
    return record;
  }, {});
};
