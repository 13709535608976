import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface IBannerProviderProps {
  bannerLoaded: boolean;
  setBannerLoaded: Dispatch<SetStateAction<boolean>>;
}

const bannerContext = createContext<IBannerProviderProps>({
  bannerLoaded: false,
  setBannerLoaded: () => null,
});

/*
	The keeps track of the banner's state across pages
	The banner should load once per session, but when the user refreshes the page, the animation should run again.
	Localstorage or SessionStorage will not be cleared on refretches and this is inherently ephemeral state
*/
export const BannerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [bannerLoaded, setBannerLoaded] = useState(false);
  return (
    <bannerContext.Provider value={{ bannerLoaded, setBannerLoaded }}>
      {children}
    </bannerContext.Provider>
  );
};

export const useBannerContext = () =>
  useContext<IBannerProviderProps>(bannerContext);
