import { Context, createContext, FunctionComponent, useState } from 'react';

import { buildContextHook } from '@r-client/shared/util/core';

import { GraphqlClient } from './data-client';
import { createErrorLink } from './link/error';
import { TReporting } from './types';

export interface IGqlClientDataProviderProps {
  client: GraphqlClient;
  reporting?: TReporting;
}

const context = createContext<GraphqlClient>(null as any);
const DataProvider: FunctionComponent<
  React.PropsWithChildren<IGqlClientDataProviderProps>
> = ({ children, client, reporting }) => {
  // Here we are concatenating error-link with existing link to our gqlClient to catch error from 1 place
  const [linkInitiated, setLinkInitiated] = useState(false);
  if (!linkInitiated) {
    client.setLink(createErrorLink(reporting).concat(client.link));
    setLinkInitiated(true);
  }
  return <context.Provider value={client}>{children}</context.Provider>;
};

export const GqlClientProvider: typeof DataProvider & {
  context: typeof context;
} = DataProvider as FunctionComponent<
  React.PropsWithChildren<IGqlClientDataProviderProps>
> & {
  context: Context<GraphqlClient>;
};

GqlClientProvider.context = context;
GqlClientProvider.displayName = 'GqlClientProvider';

export const useGqlClient = buildContextHook<GraphqlClient>(
  context,
  'GqlClientProvider'
);
