import { setContext } from '@apollo/client/link/context';

import { getLocalStorage, getWindow } from '@r-client/shared/util/core';

import { requestRefreshAccessToken } from '../refresh-request';
import {
  E_REFRESH_TOKEN_STATUS,
  IClientOpts,
  IGqlClientConfig,
  TReporting,
} from '../types';
import {
  isTokenExpired,
  isTokenExpiringSoon,
  redirectAfterInvalidation,
} from './auth-refresh-helpers';

const localStorage = getLocalStorage();

export const ACCESS_TOKEN_KEY = 'ra:token';
export const REFRESH_TOKEN_KEY = 'ra:refresh';
export const EXPIRATION_KEY = 'ra:expiration';

let refreshTokenInitiated = false;
let refreshTokenInFly:
  | ReturnType<typeof requestRefreshAccessToken>
  | undefined = undefined;

async function getRefreshedAccessToken({
  apiUri,
  apiAuthHeader,
  reporting,
}: Pick<IGqlClientConfig, 'apiAuthHeader' | 'apiUri'> & {
  reporting?: TReporting;
}) {
  const result = await requestRefreshAccessToken({
    apiUri,
    apiAuthHeader,
    reporting,
    refreshToken: localStorage?.getItem(REFRESH_TOKEN_KEY),
  });
  refreshTokenInitiated = false;
  refreshTokenInFly = undefined;
  if (result.status === E_REFRESH_TOKEN_STATUS.Error) {
    const expiration = localStorage?.getItem(EXPIRATION_KEY);

    if (!expiration || isTokenExpired(expiration)) {
      invalidateToken(reporting);
    }
  }
  return result;
}

function invalidateToken(reporting?: TReporting) {
  getLocalStorage()?.removeItem(ACCESS_TOKEN_KEY);
  getLocalStorage()?.removeItem(REFRESH_TOKEN_KEY);
  getLocalStorage()?.removeItem(EXPIRATION_KEY);

  const location = getWindow<{ location: Location }>()?.location;
  redirectAfterInvalidation(
    `/login?after_sign_in=${location?.href}`,
    reporting
  );
}

// setting JWT token in request header
export const createAuthLocalStorageLink = ({
  apiUri,
  apiAuthHeader,
  reporting,
}: Pick<IClientOpts, 'apiUri' | 'apiAuthHeader' | 'reporting'>) => {
  return setContext(async (_, { headers }) => {
    const refreshToken = localStorage?.getItem(REFRESH_TOKEN_KEY);
    const expiration = localStorage?.getItem(EXPIRATION_KEY);
    let jwtToken = localStorage?.getItem(ACCESS_TOKEN_KEY);

    // Refresh token validations
    if (
      !refreshTokenInitiated &&
      refreshToken &&
      expiration &&
      isTokenExpiringSoon(expiration)
    ) {
      refreshTokenInitiated = true;
      refreshTokenInFly = getRefreshedAccessToken({
        apiUri,
        apiAuthHeader,
        reporting,
      });
    }
    const result = await refreshTokenInFly;
    if (result?.status === E_REFRESH_TOKEN_STATUS.Success) {
      localStorage?.setItem(ACCESS_TOKEN_KEY, result.token.accessToken);
      jwtToken = result.token.accessToken;
      localStorage?.setItem(REFRESH_TOKEN_KEY, result.token.refreshToken);
      if (result.token.expiresAt)
        localStorage?.setItem(EXPIRATION_KEY, result.token.expiresAt);
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(jwtToken ? { 'Republic-Auth-Access-Token': jwtToken } : {}),
      },
    };
  });
};
