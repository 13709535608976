import { createContext } from 'react';

import { createProvider } from '../hooks/provider-context';

// #TODO: Need to remove once the new theming approach been finalized

export type TTheme = 'republic' | 'WL';
export interface IThemeProvider {
  theme: TTheme;
}

/**
 * @deprecated
 */
const { Provider: ThemeProvider, useData: useTheme } =
  createProvider<IThemeProvider>(
    'ThemeProvider',
    createContext<IThemeProvider>({ theme: 'republic' })
  );

export { ThemeProvider, useTheme };
