import { ErrorResponse, onError } from '@apollo/client/link/error';
import debug from 'debug';
import get from 'lodash/get';

import { TReporting } from '../types';

const log = debug('data:client:link:error');

export function createErrorLink(reporting?: TReporting) {
  const errorLink = onError(({ graphQLErrors, operation }: ErrorResponse) => {
    const lastError = { message: '', operationName: '' };

    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach(({ message }) => {
        log(operation, message);
        if (
          (lastError.operationName !== get(operation, 'operationName') ||
            lastError.message !== message) &&
          reporting &&
          typeof reporting.error === 'function'
        ) {
          log('Error reporting to rollbar!');
          reporting.error(
            `GraphQL                    Operation ${get(
              operation,
              'operationName'
            )} failed with ${message}`,
            {
              variables: get(operation, 'variables'),
            }
          );
          lastError.message = message;
          lastError.operationName = get(operation, 'operationName');
        }
      });
    }
  });
  return errorLink;
}
