import {
  Context,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';

import { FrontendApi } from '@ory/client';
import debug from 'debug';
import { autorun } from 'mobx';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useGqlClient, useSeedrsClient } from '@r-client/shared/data/client';
import { useReporting } from '@r-client/shared/data/error-reporting';
import { useAppConfig } from '@r-client/shared/util/core';

import { AuthModel, AuthModelPrerender } from './models/auth-model';
import { GlobalAuthModel, isGlobalAuth } from './models/global-auth-model';
import {
  GlobalViewer,
  IAuthModel,
  SeedrsViewer,
  Viewer,
} from './models/interface';
import { SeedrsAuthModel } from './models/seedrs-auth-model';
import { signOutFromSearchParams } from './util/token';

const log = debug('feature:global-auth');

export interface IAuthContext {
  auth: IAuthModel;
  seedrsAuth: IAuthModel<SeedrsViewer>;
  globalAuth: IAuthModel<GlobalViewer>;
}

export const AuthContext = createContext<IAuthContext>({
  auth: new AuthModelPrerender(),
  seedrsAuth: new AuthModelPrerender<SeedrsViewer>(),
  globalAuth: new AuthModelPrerender<GlobalViewer>(),
});

export interface IAuthProviderProps {
  children: React.ReactNode;
  seedrsAuthEnabled?: boolean;
}

const DataProvider: FC<IAuthProviderProps> = ({
  children,
  seedrsAuthEnabled,
}: IAuthProviderProps) => {
  const gqlClient = useGqlClient();
  const seedrsClient = useSeedrsClient();
  const reporting = useReporting();
  const analytics = useAnalytics();
  const applicationConfig = useAppConfig();
  const [auth, setAuth] = useState<IAuthModel>(new AuthModelPrerender());
  const [seedrsAuth, setSeedrsAuth] = useState<IAuthModel<SeedrsViewer>>(
    new AuthModelPrerender()
  );
  const [globalAuth, setGlobalAuth] = useState<IAuthModel<GlobalViewer>>(
    new AuthModelPrerender()
  );
  signOutFromSearchParams(auth, reporting);

  useEffect(() => {
    log('Legacy Auth enabled');
    setAuth(
      new AuthModel({
        analytics,
        client: gqlClient,
        reporting,
        authPersistence: applicationConfig?.authPersistence,
      })
    );
  }, [analytics, gqlClient, reporting, applicationConfig?.authPersistence]);
  useEffect(() => {
    if (
      seedrsAuthEnabled &&
      seedrsClient &&
      analytics &&
      reporting &&
      seedrsAuth instanceof AuthModelPrerender
    ) {
      log('SeedrsAuth enabled');
      setSeedrsAuth(
        new SeedrsAuthModel({
          analytics,
          client: seedrsClient,
          reporting,
        })
      );
    }
  }, [
    analytics,
    seedrsClient,
    reporting,
    applicationConfig?.authPersistence,
    seedrsAuthEnabled,
    seedrsAuth,
  ]);

  useEffect(() => {
    if (applicationConfig?.globalAuthBaseUrl) {
      log('Global Auth enabled');
      setGlobalAuth(
        new GlobalAuthModel({
          globalAuthBaseUrl: applicationConfig?.globalAuthBaseUrl,
        })
      );
    }
  }, [applicationConfig?.globalAuthBaseUrl]);

  useEffect(() =>
    autorun(() => {
      if (auth.viewer?.info?.id) {
        reporting.configure({
          payload: {
            person: {
              id: auth.viewer?.info?.id,
              username: auth.viewer?.info?.slug,
            },
          },
        });
      }
    })
  );

  return (
    <AuthContext.Provider value={{ auth, seedrsAuth, globalAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider: typeof DataProvider & {
  AuthContext: typeof AuthContext;
} = DataProvider as FC<React.PropsWithChildren<unknown>> & {
  AuthContext: Context<IAuthContext>;
};

AuthProvider.AuthContext = AuthContext;
AuthProvider.displayName = 'AuthProvider';

export const useAuth = (): IAuthModel<Viewer> => {
  const { auth } = useContext<IAuthContext>(AuthContext);
  return auth;
};

export const useSeedrsAuth = (): IAuthModel<SeedrsViewer> => {
  const { seedrsAuth } = useContext<IAuthContext>(AuthContext);
  return seedrsAuth;
};

export const useGlobalAuth = (): IAuthModel<GlobalViewer> => {
  const { globalAuth } = useContext<IAuthContext>(AuthContext);
  return globalAuth;
};

export const useGlobalAuthApi = (): FrontendApi | undefined => {
  const { globalAuth } = useContext<IAuthContext>(AuthContext);
  if (!globalAuth || !isGlobalAuth(globalAuth)) return;
  return globalAuth.ory;
};
