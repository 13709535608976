import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: defineStyle({
    img: {
      overflow: 'hidden',
      border: '1px',
      borderColor: 'grey.50',
    },
  }),
});

const variantRepublic = definePartsStyle({
  container: defineStyle({
    img: {
      border: 0,
    },
  }),
});

const sizes = {
  'republic-xs': defaultTheme.components.Avatar.sizes?.xs ?? {},
  'republic-sm': defaultTheme.components.Avatar.sizes?.sm ?? {},
  'republic-md': defaultTheme.components.Avatar.sizes?.md ?? {},
  'republic-lg': defaultTheme.components.Avatar.sizes?.lg ?? {},
  'republic-xl': defaultTheme.components.Avatar.sizes?.xl ?? {},

  xs: definePartsStyle({
    container: defineStyle({
      width: '24px',
      height: '24px',
      borderRadius: 'sm',
      img: {
        borderRadius: 'sm',
      },
    }),
  }),
  sm: definePartsStyle({
    container: defineStyle({
      width: '36px',
      height: '36px',
      borderRadius: 'md',
      img: {
        borderRadius: 'md',
      },
    }),
  }),
  md: definePartsStyle({
    container: defineStyle({
      width: '48px',
      height: '48px',
      borderRadius: 'lg',
      img: {
        borderRadius: 'lg',
      },
    }),
  }),
  lg: definePartsStyle({
    container: defineStyle({
      width: '64px',
      height: '64px',
      borderRadius: 'xl',
      img: {
        borderRadius: 'xl',
      },
    }),
  }),
  xl: definePartsStyle({
    container: defineStyle({
      width: '96px',
      height: '96px',
      borderRadius: '2xl',
      img: {
        borderRadius: '2xl',
      },
    }),
  }),
  xxl: definePartsStyle({
    container: defineStyle({
      width: '160px',
      height: '160px',
      borderRadius: '2xl',
      img: {
        borderRadius: '2xl',
      },
    }),
  }),
};

export const Avatar = defineMultiStyleConfig({
  baseStyle,

  sizes,

  variants: {
    republic: variantRepublic,
  },
});
