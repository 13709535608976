/* eslint-disable @nx/enforce-module-boundaries */
import { defineStyleConfig, StyleFunctionProps, theme } from '@chakra-ui/react';

const primary = (props: StyleFunctionProps) => ({
  ...theme.components.Button.variants?.solid(props),
  bg: 'blue.500',
  color: 'white',
  borderColor: 'transparent',
  _hover: {
    bg: ['blue.500', 'blue.200'],
    _disabled: {
      bg: 'blue.200',
      color: 'blue.50',
    },
  },
  _active: {
    bg: 'blue.800',
  },
  _disabled: {
    bg: 'blue.200',
    color: 'blue.50',
  },
});

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    border: '0.1',
    minW: '40',
    height: 'auto',
    borderRadius: 'lg',
    _focusVisible: {
      outline: 0,
      boxShadow: '0 0 0 1px white, 0 0 0 3px #6691FF',
    },
  },
  variants: {
    primary,
    'republic-default': {
      bg: 'white',
      boxShadow: 'inset 0 0 0 1px #c7c7c7',
      color: 'black',
      _hover: {
        borderColor: 'grey.200',
        boxShadow: 'inset 0 0 0 1px #0049FF',
        color: 'blue.500',
        _disabled: {
          boxShadow: 'inset 0 0 0 1px #e0e0e0',
          color: 'rgba(26, 26, 26, 0.3)',
        },
      },
      _disabled: {
        opacity: '1',
        boxShadow: 'inset 0 0 0 1px #e0e0e0',
        color: 'rgba(26, 26, 26, 0.3)',
      },
    },
    'republic-primary': {
      bg: 'blue.500',
      color: 'white',
      _hover: {
        bgColor: 'blue.400',
        _disabled: {
          bg: 'rgba(0, 73, 255, 0.5)',
          opacity: '1',
        },
      },
      _disabled: {
        bg: 'rgba(0, 73, 255, 0.5)',
        opacity: '1',
      },
    },
    'republic-secondary': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.outline(props),
      bg: 'white',
      color: 'grey.900',
      borderColor: 'grey.200',
      _hover: {
        color: 'blue.500',
        borderColor: 'blue.500',
        _disabled: {
          bg: 'white',
          color: 'grey.300',
          borderColor: 'grey.200',
        },
      },
      _active: {
        bg: 'grey.50',
        color: 'blue.800',
        borderColor: 'blue.800',
      },
      _disabled: {
        bg: 'white',
        color: 'grey.300',
      },
    }),

    'republic-tertiary': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.outline(props),
      px: '5',
      py: '2',
      border: '0',
      minW: 'auto',
      color: 'blue.500',
      textDecoration: 'underline',
      textUnderlineOffset: 3,
      _hover: {
        color: 'blue.300',
        _disabled: {
          color: 'grey.300',
        },
      },
      _active: {
        color: 'blue.800',
      },
      _disabled: {
        color: 'grey.300',
      },
    }),
    'republic-toggle': {
      bg: 'grey.100',
      fontSize: 'xs',
      fontWeight: 'semibold',
    },
    'republic-danger': {
      bg: 'red.800',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'red.800',
          opacity: 0.4,
        },
      },
      _disabled: {
        bg: 'red.800',
        opacity: 0.4,
      },
    },
    'republic-viewContents': {
      p: 0,
      px: 0,
      height: 'unset',
      minW: 'unset',
    },
  },
  sizes: {
    'republic-default': {
      py: '3',
      px: '6',
    },
    default: {
      py: '3',
      px: '6',
    },
    'republic-large': {
      fontSize: '22px',
      py: '5',
      px: '8',
      borderRadius: 'xl',
    },
  },
  defaultProps: {
    size: 'default',
    variant: 'primary',
  },
});
