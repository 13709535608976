// eslint-disable-next-line no-restricted-imports
import type Rollbar from 'rollbar';

import { getFullStory } from '@r-client/shared/util/core';

import {
  getIgnoredMessages,
  HOST_BLOCK_LIST,
  isIgnoredByFilename,
  isIgnoredByHost,
} from './filters';

export type RollbarConfiguration = Rollbar.Configuration;
export interface IConfigurationSettings {
  /**
   * API integration token. if no token passed no reporting provided
   */
  accessToken: string | undefined;
  environment: 'production' | 'staging';
  codeVersion: string | undefined;
  serverHost: string;
  /**
   * kind is self control key not to miss configuration update on vendor change
   */
  kind: 'rollbar';
}

export const createReportingConfig = (
  settings: IConfigurationSettings
): Rollbar.Configuration => {
  // see https://docs.rollbar.com/docs/rollbarjs-configuration-reference
  return {
    accessToken: settings.accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    /** Filters items by `filename` in `body.trace_chain` or `body.trace` */
    hostBlockList: HOST_BLOCK_LIST,
    ignoredMessages: getIgnoredMessages(settings.environment),
    checkIgnore: (...args) => {
      return isIgnoredByHost(...args) || isIgnoredByFilename(...args);
    },
    payload: {
      environment: settings.environment,
      client: {
        javascript: {
          code_version: settings.codeVersion,
          source_map_enabled: true,
        },
      },
      server: {
        host: settings.serverHost,
      },
    },
    transform: (payload) => {
      // Always add FullStory URL to the payload
      const fs = getFullStory();
      const fullStoryUrl = fs?.getCurrentSessionURL(true);

      if (fullStoryUrl) {
        (payload as { fullStoryUrl: string }).fullStoryUrl = fullStoryUrl;
      }
    },
  };
};
