// This is the only module where we import rollbar and deps
// eslint-disable-next-line no-restricted-imports
import { PropsWithChildren } from 'react';

// We break the rule here as this the place where we want to expose the whole package here
// eslint-disable-next-line no-restricted-imports
import {
  Provider,
  ProviderProps,
  RollbarContext,
  useRollbar,
  useRollbarContext,
  useRollbarPerson,
} from '@rollbar/react';

import { FakeReporter } from './fake-reporter';
import { Reporter } from './reporter';

export const ErrorReportingContext = RollbarContext;

export interface IErrorReportingProviderProps
  extends Omit<ProviderProps, 'config'> {
  config: Reporter.Configuration;
}

export const ErrorReportingProvider = (
  props: PropsWithChildren<IErrorReportingProviderProps>
) => {
  let instance: Reporter | undefined = undefined;
  if (
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV !== 'production' ||
    // eslint-disable-next-line no-process-env
    process.env.STORYBOOK ||
    typeof window === 'undefined' ||
    !props.config.accessToken
  ) {
    instance = FakeReporter.init({ accessToken: 'fakeAccessToken' });
  }
  return (
    <Provider instance={instance} {...props}>
      {props.children}
    </Provider>
  );
};

export type Reporting = Reporter;

export const useReporting = useRollbar;
export const useReportingContext = useRollbarContext;

export type Person = {
  id: string;
  username?: string;
  email?: string;
};

export const useReportingPerson = (person: Person) => useRollbarPerson(person);
