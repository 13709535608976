// https://github.com/GoogleChrome/web-vitals
/* TODO: report web-vitals
  https://linear.app/republic/issue/RCG-1799/[fw-feat]-report-web-vitals
 */
import { onCLS } from 'web-vitals';

export function initWebVitals() {
  // eslint-disable-next-line no-console
  return onCLS(console.log, { reportAllChanges: true });
}
