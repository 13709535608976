import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  closeButton: {
    color: 'grey.400',
  },
});

export const Modal = {
  baseStyle,
  variants: {
    'republic-dls': {
      dialog: {
        p: '10',
        borderRadius: '3xl',
      },
      overlay: {
        bg: ['white', 'rgba(0, 0, 0, 0.25)'],
      },
      body: {
        px: '0',
        py: '5',
      },
      header: {
        px: '0',
        pb: '2',
      },
      footer: {
        px: '0',
      },
    },
  },

  sizes: {
    // TODO: Update with unit references from sizes
    'republic-sm': {
      dialog: {
        // maxW: 'container.sm',
        maxW: '640px',
      },
    },
    'republic-md': {
      dialog: {
        // maxW: 'container.md',
        maxW: '768px',
      },
    },
    'republic-lg': {
      dialog: {
        // maxW: 'container.lg',
        maxW: '1024px',
      },
    },
    'republic-xl': {
      dialog: {
        maxW: 'container.xl',
      },
    },
  },
  defaultProps: {
    size: 'republic-md',
  },
};
