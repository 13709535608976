import type FullStory from '@fullstory/browser';

import { typeCast } from './utils';

export function getWindow<T = Window & typeof globalThis>(): T | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return typeCast<T>(window);
}

export function getDocument(): Document | undefined {
  if (typeof document === 'undefined') {
    return undefined;
  }
  return document;
}

export function getLocalStorage(): Storage | undefined {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  return localStorage;
}

export function getSessionStorage(): Storage | undefined {
  if (typeof sessionStorage === 'undefined') {
    return undefined;
  }
  return sessionStorage;
}

declare const FS: typeof FullStory;
export function getFullStory(): typeof FullStory | undefined {
  if (typeof FS === 'undefined') {
    return undefined;
  }
  return FS;
}

interface IMonolithCallbacks {
  [name: string]: () => void;
}

export function runMonolithIFrameCallback(callbackName: string) {
  const window = getWindow<{ monolithCallbacks?: IMonolithCallbacks }>();
  window?.monolithCallbacks?.[callbackName]?.();
}
