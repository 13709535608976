import { AxiosQueryModel } from '@r-client/shared/data/client';

export type ISeedrsUserStatusResult = {
  result: {
    id: string;
    first_name: string;
    last_name: string;
    roles: string[];
  };
};

export class SeedrsUserQuery extends AxiosQueryModel<ISeedrsUserStatusResult> {
  protected query() {
    return this.client.get<ISeedrsUserStatusResult>('/me', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
