import debug from 'debug';

import { Maybe } from '@r-client/data/graphql-types';

import {
  RefreshTokenDocument,
  RefreshTokenMutationResult,
} from '../graphql/types';
import { getInfraAuthHeader, isInfraAuthRequired } from './link/infra-header';
import {
  E_REFRESH_TOKEN_STATUS,
  IGqlClientConfig,
  TRefreshTokenResult,
  TReporting,
} from './types';

const log = debug('data:client:refresh-request');

export async function requestRefreshAccessToken({
  apiUri,
  apiAuthHeader,
  refreshToken,
  reporting,
}: Pick<IGqlClientConfig, 'apiAuthHeader' | 'apiUri'> & {
  reporting?: TReporting;
  refreshToken?: Maybe<string>;
}): Promise<TRefreshTokenResult> {
  try {
    const request = await fetch(apiUri, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        ...(isInfraAuthRequired(apiAuthHeader)
          ? getInfraAuthHeader(apiAuthHeader)
          : {}),
      },
      credentials: 'include',
      // TODO: https://linear.app/republic/issue/RCE-1347/graphql-refresh-token-handle-error-object
      // Update mutation with errors object to handle invalid refresh token case.
      body: JSON.stringify({
        query: RefreshTokenDocument?.loc?.source.body,
        variables: {
          refreshToken,
        },
      }),
    });
    const response = (await request.json()) as RefreshTokenMutationResult;

    if (response.data?.Jwt_refreshToken?.errors?.length) {
      log(
        'Refresh Token Query Errors: ',
        response.data?.Jwt_refreshToken?.errors[0]?.message
      );
      reporting?.debug(
        'Refresh Token Query Errors: ',
        response.data?.Jwt_refreshToken?.errors[0]?.message
      );
      return {
        status: E_REFRESH_TOKEN_STATUS.Error,
        error: new Error(response.data.Jwt_refreshToken.errors[0]?.message),
      };
    }

    if (!response.data?.Jwt_refreshToken?.token?.accessToken) {
      return {
        status: E_REFRESH_TOKEN_STATUS.Error,
        error: new Error('No access token in response'),
      };
    }

    return {
      status: E_REFRESH_TOKEN_STATUS.Success,
      token: response.data?.Jwt_refreshToken?.token,
    };
  } catch (error) {
    // logout, show alert or something
    log('Refresh Token failed: ', error);
    reporting?.debug('Refresh Token failed:', error as Error);

    const e = error instanceof Error ? error : new Error(String(error));
    return { status: E_REFRESH_TOKEN_STATUS.NetworkError, error: e };
  }
}
