/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  IAuthenticateWithTwoFactorMutation,
  AuthenticateWithTwoFactorDocument,
  IAuthenticateWithTwoFactorMutationVariables,
} from './types';
import { IAuthAuthenticateWithTwoFactorPayload } from '@r-client/data/graphql-types';
export type { IAuthAuthenticateWithTwoFactorPayload };

export class AuthenticateWithTwoFactorMutation extends BaseMutationModel<
  IAuthenticateWithTwoFactorMutation,
  IAuthenticateWithTwoFactorMutationVariables
> {
  get mutation() {
    return AuthenticateWithTwoFactorDocument;
  }
}
export function useAuthenticateWithTwoFactorMutation(
  opts?: Omit<
    IMutationModelOpts<IAuthenticateWithTwoFactorMutationVariables>,
    'client'
  >
) {
  const client = useGqlClient();
  return useLocalObservable(
    () => new AuthenticateWithTwoFactorMutation({ ...opts, client })
  );
}
