/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetFeatureToggleQueryVariables = Types.Exact<{
  feature: Types.IFlipperEnum;
}>;

export type IGetFeatureToggleQuery = {
  __typename?: 'Query';
  flipper: { __typename?: 'Flipper'; enabled: boolean; viewed: boolean };
};

export const GetFeatureToggleDocument = gql`
  query GetFeatureToggle($feature: FlipperEnum!) {
    flipper(feature: $feature) {
      enabled
      viewed
    }
  }
`;
export type GetFeatureToggleQueryResult = Apollo.QueryResult<
  IGetFeatureToggleQuery,
  IGetFeatureToggleQueryVariables
>;
