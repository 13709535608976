import { DefaultContext } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const getInfraAuthHeader = (apiAuthHeader?: string) => ({
  Authorization: apiAuthHeader,
});

export const isInfraAuthRequired = (apiAuthHeader?: string) => !!apiAuthHeader;

export const createInfraAuth = (apiAuthHeader?: string) => {
  return setContext(
    async (_, context: DefaultContext | Promise<DefaultContext>) => {
      const { headers } = await context;
      if (isInfraAuthRequired(apiAuthHeader)) {
        return {
          headers: {
            ...headers,
            ...getInfraAuthHeader(apiAuthHeader),
          },
        };
      }
    }
  );
};
