import { useEffect } from 'react';

import type { NextRouter } from 'next/router';

import { useReporting } from '../reporter-context';

export interface IContextReporterProps {
  useRouter(): NextRouter;
}

/**
 * This component will watch NextRouter updates for you app.
 * Just add it to layout and ensure it renders
 */
export const NavigationContextHandler = ({
  useRouter,
}: IContextReporterProps) => {
  const router = useRouter();
  const reporting = useReporting();
  useEffect(() => {
    reporting.configure({ payload: { context: router.pathname } });
  }, [reporting, router, router.pathname]);
  return null;
};
