/* eslint-disable @nx/enforce-module-boundaries */
import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  popoverAnatomy.keys
);

const $arrowBg = cssVar('popper-arrow-bg');

export const Popover = defineMultiStyleConfig({
  variants: {
    republic: {
      content: {
        background: 'grey.900',
        color: 'white',
        px: '3',
        py: '2.5',
        borderRadius: 'md',
        border: 'none',
      },
      arrow: {
        [$arrowBg.variable]: '#1A1A1A',
      },
    },
  },
});
