import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Progress: ComponentStyleConfig = {
  baseStyle: {
    filledTrack: {
      bg: 'green.500',
    },
    track: {
      bg: 'grey.100',
    },
  },
  defaultProps: {},
};
