import CN from 'clsx';

import { Box, IBoxProps } from '@r-client/shared/ui/core';
import {
  brm2px,
  CssBuilderGeneric,
  TMedia,
  TSizeWithBrm,
} from '@r-client/shared/util/core';

import styles from './flex.module.scss';

export const directionOptions = [
  'row',
  'column',
  'row-reverse',
  'column-reverse',
] as const;
export const wrapOptions = ['wrap', 'nowrap', 'wrap-reverse'] as const;
export const justifyContentOptions = [
  'normal',
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
] as const;
export const alignItemsOptions = [
  'normal',
  'flex-start',
  'flex-end',
  'center',
  'stretch',
  'baseline',
] as const;
export const alignContentOptions = [
  'normal',
  'flex-start',
  'flex-end',
  'center',
  'stretch',
  'space-between',
  'space-around',
] as const;

type TDirection = typeof directionOptions[number];
type TWrap = typeof wrapOptions[number];
type TJustifyContent = typeof justifyContentOptions[number];
type TAlignItems = typeof alignItemsOptions[number];
type TAlignContent = typeof alignContentOptions[number];

export interface IFlexProps extends IBoxProps {
  direction?: TMedia<TDirection>;
  wrap?: TMedia<TWrap>;
  justifyContent?: TMedia<TJustifyContent>;
  alignItems?: TMedia<TAlignItems>;
  alignContent?: TMedia<TAlignContent>;
  gap?: TMedia<TSizeWithBrm>;
}

const cssPropsMapping = {
  direction: { style: 'flex-direction', cssVar: false },
  wrap: { style: 'flex-wrap', cssVar: false },
  justifyContent: { style: 'justify-content', cssVar: false },
  alignItems: { style: 'align-items', cssVar: false },
  alignContent: { style: 'align-content', cssVar: false },
  gap: { style: 'gap', cssVar: true, convert: brm2px },
  hidden: { style: 'hidden', cssVar: false },
};

export const Flex = (props: IFlexProps) => {
  const {
    className,
    children,
    direction,
    wrap,
    justifyContent,
    alignItems,
    alignContent,
    gap,
    ...params
  } = props;

  const builder = new CssBuilderGeneric(
    styles,
    props,
    cssPropsMapping,
    Flex.defaultProps
  );
  const style = Object.assign(params.style || {}, builder.cssVars);
  const allClassNames = CN(styles.flex, className, builder.classNames);
  return (
    <Box className={allClassNames} style={style} {...params}>
      {children}
    </Box>
  );
};

Flex.defaultProps = {
  direction: 'row',
  wrap: 'nowrap',
  justifyContent: 'normal',
  alignItems: 'normal',
  alignContent: 'normal',
} as Partial<IFlexProps>;
