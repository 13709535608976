import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import type { StyleFunctionProps } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { theme as defaultTheme } from '@chakra-ui/theme';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const variantRepublicLine: PartsStyleFunction<typeof parts> = (props) => ({
  ...lineVariant(props),

  tablist: {
    ...lineVariant(props)?.tablist,
    borderBottomColor: 'grey.100',
    borderBottomWidth: '2px',
  },

  tab: {
    ...lineVariant(props)?.tab,
    padding: '15px 20px 8px',
    color: 'grey.500',
    fontWeight: '500',

    _selected: {
      ...lineVariant(props)?.tab._selected,
      fontWeight: '700',
      color: 'blue.500',
      borderColor: 'blue.500',
    },
  },

  tabpanel: {
    px: 0,
  },
});

export const Tabs: ComponentStyleConfig = {
  variants: {
    'republic-line': variantRepublicLine,
  },
};

function lineVariant(props: StyleFunctionProps) {
  return defaultTheme.components.Tabs.variants?.line(props);
}
