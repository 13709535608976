import {
  createErrorReporting,
  createReportingConfig,
} from '@r-client/shared/data/error-reporting';

import { getPublicConfig, getServerConfig } from './application.config';

export const createServerErrorReporting = () => {
  const publicConfig = getPublicConfig();
  const serverConfig = getServerConfig();
  return createErrorReporting(
    createReportingConfig({
      accessToken: serverConfig.rollbarServerToken,
      serverHost: publicConfig.graphqlEndpointUrl,
      codeVersion: publicConfig.applicationVersion,
      environment: publicConfig.applicationEnvironment,
      kind: 'rollbar',
    })
  );
};
