import get from 'lodash/get';

import { Reporter } from './reporter';

export type IgnoreFilter = NonNullable<Reporter.Configuration['checkIgnore']>;
type IgnoredMessage = string | RegExp;

const LOCALHOST_URLS = ['http://localhost', 'https://localhost'];

export const HOST_BLOCK_LIST = [
  'file:///',
  'chrome-extension://',
  'https://analytics.tiktok.com',
  ...LOCALHOST_URLS,
];

const URL_BLOCK_LIST = ['file:///', ...LOCALHOST_URLS];

const FILENAME_BLOCK_LIST = [
  'SyntaxError: Unexpected token',
  '(unknown)',
  'https://s.pinimg.com',
  'https://www.youtube.com/s/player/',
];

const MESSAGE_BLOCK_LIST_ANY_ENV: IgnoredMessage[] = [
  /^GraphQL.+Operation (\w)+ failed with You are not (authorized|allowed) to perform this action/,
];

const MESSAGE_BLOCK_LIST_PRODUCTION: IgnoredMessage[] = [
  ...MESSAGE_BLOCK_LIST_ANY_ENV,
  /^Minified React error #418[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=418
  /^Minified React error #423[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=423
  /^Minified React error #425[^0-9].*/, // https://reactjs.org/docs/error-decoder.html?invariant=425
  // This is quite rare and is hard to debug, might happen when the user has connection issues
  // or just slow connection, reloads the page or closes the tab before the request is finished.
  /^The user aborted a request\./,
  /^Error: Invariant: attempted to hard navigate to the same URL.*/,
  /^TypeError: Failed to fetch/,
];
const MESSAGE_BLOCK_LIST_STAGING: IgnoredMessage[] = [
  ...MESSAGE_BLOCK_LIST_ANY_ENV,
];

export const getIgnoredMessages = (
  environment: 'production' | 'staging'
): IgnoredMessage[] => {
  return environment === 'production'
    ? MESSAGE_BLOCK_LIST_PRODUCTION
    : MESSAGE_BLOCK_LIST_STAGING;
};

export const isIgnoredByHost: IgnoreFilter = (_isUncaught, _args, item) => {
  try {
    const url = get(item, 'request.url', '') as string;
    if (!url) return false;
    return URL_BLOCK_LIST.some((blockedHost) => url.startsWith(blockedHost));
  } catch {
    return false;
  }
};

type Frame = {
  filename?: string;
};

export const isIgnoredByFilename: IgnoreFilter = (_isUncaught, _args, item) => {
  try {
    const frames = get(item, 'body.trace.frames', []) as Frame[];
    return frames.some(
      ({ filename }) =>
        filename &&
        FILENAME_BLOCK_LIST.some((blockedFile) =>
          filename.startsWith(blockedFile)
        )
    );
  } catch {
    return false;
  }
};
