import { createContext, createElement, FC, useContext } from 'react';

import { getWindow } from '../../index';

// Here we setup strict configuration typing and control its usage.

/*
 * Begin deprecated legacy config definitions.
 */

function browserCanUseCssVariables(): boolean {
  const supports = getWindow()?.CSS?.supports;
  return supports ? supports('color', 'var(--fake-var)') : true;
}

const context = createContext({
  cssVarsSupport: browserCanUseCssVariables(),
});

/**
 * @deprecated For application config, use useAppConfig from '@r-client/shared/util/core'
 */
export const useConfig = () => useContext(context);

/*
 * Begin current config definitions.
 */

export type TAuthPersistence =
  | {
      type: 'none';
    }
  | {
      type: 'localStorage';
    }
  | {
      type: 'cookie';
      cookiePrefix: string | undefined;
    };

export interface IPublicConfig {
  appLastUpdated?: string;
  applicationEnvironment: 'production' | 'staging';
  applicationURLOrigin: string;
  applicationVersion?: string;
  appReleaseVersion?: string;
  authPersistence: TAuthPersistence;
  brazeApiKey?: string;
  brazeBaseUrl?: string;
  comingSoonTokensList?: string;
  froalaKey?: string;
  globalAuthBaseUrl?: string;
  googleMapsApiKey?: string;
  googleMapsEmbedUrl?: string;
  googleRecaptchaSiteKey?: string;
  graphqlEndpointUrl: string;
  republicEuropeBaseUrl?: string;
  rollbarClientToken?: string;
  seedrsApiBaseUrl?: string;
  segmentClientWriteKey?: string;
  wallabyApiKey?: string;
  wallabyBaseUrl?: string;
  zendeskKey?: string;
  unifyApiKey?: string;
}

// Should be null to enforce Config value at app level
// Usage outside of application should return null correctly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const configContext = createContext<IPublicConfig>(null as any);

export const ConfigProvider: FC<
  React.PropsWithChildren<{ value: IPublicConfig }>
> = ({ children, value }) => {
  return createElement(configContext.Provider, { value }, children);
};

export const useAppConfig = () => useContext<IPublicConfig>(configContext);
