import debug from 'debug';
import { makeAutoObservable } from 'mobx';

import { Reporting } from '@r-client/shared/data/error-reporting';
import { TAuthPersistence } from '@r-client/shared/util/core';

import {
  Guest,
  IAuthModel,
  ISeedrsAuthModelOpts,
  MaybeValue,
  SeedrsUser,
  SeedrsViewer,
} from './interface';
import {
  ISeedrsUserStatusResult,
  SeedrsUserQuery,
} from './seedrs-user-query-model';
const log = debug('feature:seedrs-auth');

export type RequestQueryModelState = 'pending' | 'fulfilled' | 'rejected';

const createGuest = (): Guest => ({
  isAuthenticated: false,
  isAdmin: false,
  info: undefined,
});

const createUser = (
  info: NonNullable<ISeedrsUserStatusResult['result']>
): SeedrsUser => ({
  isAuthenticated: true,
  isAdmin: info.roles.includes('admin') || info.roles.includes('staff'),
  info,
});

export class SeedrsAuthModel implements IAuthModel<SeedrsViewer> {
  private userQuery: SeedrsUserQuery;
  private reporting: Reporting;
  private authPersistence: TAuthPersistence = { type: 'none' };

  private canSessionExist = true;

  constructor(opts: ISeedrsAuthModelOpts) {
    makeAutoObservable(this);
    this.reporting = opts.reporting;
    this.userQuery = new SeedrsUserQuery({
      variables: {},
      client: opts.client,
    });
    this.userQuery.fetch();
  }

  reloadSession = () => {
    log('UserQuery reload forcefully!');
    // This quirky logic is required to cover the case
    // that a query can be initiated in the `constructor`
    this.canSessionExist = true;
    // this.userQuery.subscribe();
    this.userQuery.fetch();
    return undefined;
  };

  signIn = () => {
    throw new Error('Method not implemented.');
  };

  signOut = () => {
    throw new Error('Method not implemented.');
  };

  authenticateWithTwoFactor = () => {
    throw new Error('Method not implemented.');
  };

  get viewer(): MaybeValue<SeedrsViewer> {
    if (!this.canSessionExist) return createGuest();
    if (!this.userQuery.value) return undefined;
    return this.userQuery.value.result.id
      ? createUser(this.userQuery.value.result)
      : createGuest();
  }

  get isLoading(): boolean {
    return this.userQuery.state === 'pending';
  }

  get errors() {
    return this.userQuery.error;
  }
}
