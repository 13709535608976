import { useEffect } from 'react';

import { useLocalObservable } from 'mobx-react-lite';

export interface IQuery<TVariables> {
  variables?: TVariables | undefined;
  setVariables(variables: TVariables | undefined): void;
}

export function useQuery<
  TVariables extends { [key: string]: unknown },
  TModel extends IQuery<TVariables>
>(initializer: () => TModel, variables: TVariables | undefined): TModel {
  const model = useLocalObservable(initializer);

  useEffect(() => {
    model.setVariables(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return model;
}
