import React, { ForwardedRef, useMemo } from 'react';

import CN from 'clsx';

import {
  brm2px,
  createBooleanGetter,
  createStringGetter,
  CssBuilder,
  CssBuilderInline,
  dataRole,
  getClassName,
  TAlign,
  TIndent,
  TMedia,
  TVal,
  useConfig,
} from '@r-client/shared/util/core';

import { COLORS, TColors } from '../styles';

import styles from './text.module.scss';

export type ITagValue = keyof JSX.IntrinsicElements;
export type TType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'bodyBig'
  | 'bodyMedium'
  | 'bodySmall';

export type TWeight =
  | { bold?: boolean; light?: undefined }
  | { light?: boolean; bold?: undefined };

type TWeightType = '300' | '400' | '500' | '600' | '700' | '800' | '900';
type TLineHeight = '0.9' | '1' | '1.2' | '1.3' | '1.4';
export interface ITextProps
  extends Omit<React.HTMLAttributes<HTMLParagraphElement>, 'color'> {
  as?: Omit<keyof JSX.IntrinsicElements, 'div'>;
  m?: TVal<TIndent>; // margin
  p?: TVal<TIndent>; // padding
  type?: TMedia<TType>;
  color?: TColors | TMedia<TColors>;
  align?: TMedia<TAlign>;
  weight?: TMedia<TWeightType>;
  lineHeight?: TMedia<TLineHeight>;
  bold?: TMedia<boolean>;
  light?: TMedia<boolean>;
  underline?: TMedia<boolean>;
  italic?: TMedia<boolean>;
}

const getters = {
  getType: createStringGetter<TType>((name, size) => {
    const key = getClassName(`${name}N` as const, size);
    return (styles as any)[key];
  }),
  getWeight: createStringGetter<TWeightType>((name, size) => {
    const key = getClassName(`weight${name}` as const, size);
    return (styles as any)[key];
  }),
  getAlign: createStringGetter<TAlign>((name, size) => {
    const key = getClassName(`${name}` as const, size);
    return (styles as any)[key];
  }),
  getLineHeight: createStringGetter<TLineHeight>((name, size) => {
    const n = name.replace('.', '_');
    const key = getClassName(`lineHeight${n}`, size);
    return (styles as any)[key];
  }),
  getLight: createBooleanGetter((name, size) => {
    if (!name) {
      return undefined;
    }
    const key = getClassName('light', size);
    return (styles as any)[key];
  }),
  getBold: createBooleanGetter((name, size) => {
    if (!name) {
      return undefined;
    }
    const key = getClassName('bold', size);
    return (styles as any)[key];
  }),
  getUnderline: createBooleanGetter((name, size) => {
    if (!name) {
      return undefined;
    }
    const key = getClassName('underline', size);
    return (styles as any)[key];
  }),
  getItalic: createBooleanGetter((name, size) => {
    if (!name) {
      return undefined;
    }
    const key = getClassName('italic', size);
    return (styles as any)[key];
  }),
  getColor: createStringGetter<TColors>((name, size) => {
    const key = getClassName(`color${name}`, size);
    return (styles as any)[key];
  }),
};

/** @deprecated Use Text from Chakra UI instead */
export const Text = React.forwardRef<HTMLElement | undefined, ITextProps>(
  function Text(
    {
      m,
      p,
      className,
      type,
      light,
      bold,
      weight,
      color,
      align,
      underline,
      lineHeight,
      italic,
      ...params
    },
    ref
  ) {
    const colorStyle =
      color && typeof color === 'string'
        ? COLORS[color]
        : color
        ? getters.getColor(color)
        : undefined;

    const classNames = CN(
      className,
      colorStyle,
      {
        [styles.h1]: type === 'h1',
        [styles.h2]: type === 'h2',
        [styles.h3]: type === 'h3',
        [styles.h4]: type === 'h4',
        [styles.h5]: type === 'h5',
        [styles.bodyBig]: type === 'bodyBig',
        [styles.bodyMedium]: type === 'bodyMedium',
        [styles.bodySmall]: type === 'bodySmall',
      },
      getters.getType(type),
      getters.getWeight(weight),
      getters.getAlign(align),
      getters.getLineHeight(lineHeight),
      getters.getLight(light),
      getters.getBold(bold),
      getters.getUnderline(underline),
      getters.getItalic(italic)
    );
    const config = useConfig();
    const props: React.PropsWithChildren<ITextPropsFix> = {
      ...params,
      p: brm2px(p),
      m: brm2px(m),
      className: CN(classNames),
    };
    // TODO: Need to create reusable function for this to support with any elements
    return config.cssVarsSupport
      ? TextCssVariables(props, ref)
      : TextInline(props, ref);
  }
);

interface ITextPropsFix extends Omit<ITextProps, 'p' | 'm'> {
  m?: TVal<string>; // margin
  p?: TVal<string>; // padding
}

let counter = 0;
function TextInline(
  props: React.PropsWithChildren<ITextPropsFix>,
  ref: ForwardedRef<HTMLElement | undefined>
) {
  const { as = 'p', m, p, className, children, role, ...params } = props;
  const cssName = useMemo(() => `text-${counter++}`, []);
  const b = new CssBuilderInline(cssName, { ...params, m, p });
  const el = React.createElement(
    as.toString(),
    {
      ...params,
      className: CN(className, b.classNames),
      ...dataRole(role),
      ref,
    },
    children
  );
  return (
    <>
      <style
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: b.styleTag }}
      />
      {el}
    </>
  );
}

function TextCssVariables(
  props: React.PropsWithChildren<ITextPropsFix>,
  ref: ForwardedRef<HTMLElement | undefined>
) {
  const { as = 'p', m, p, className, children, ...params } = props;
  const b = new CssBuilder(styles, { ...params, m, p });
  return React.createElement(
    as.toString(),
    {
      ...params,
      className: CN(className, b.classNames),
      style: b.style,
      ref,
    },
    children
  );
}
