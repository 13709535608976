// /* eslint-disable @nx/enforce-module-boundaries */
import { inputAnatomy } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const republicInputBaseStyles = {
  border: '1px solid',
  borderColor: 'brand.grey.e0',
  borderRadius: 'md',
  height: '10',
  bg: 'white',
  _hover: {
    borderColor: 'brand.grey.c4',
  },
  _focusVisible: {
    borderColor: 'brand.primary',
    boxShadow: '0 0 2px 2px rgb(85 85 85 / 5%)',
  },
  _placeholder: {
    color: 'brand.grey.ca',
  },
  _disabled: {
    borderColor: 'brand.grey.e0',
    bg: 'white',
  },
};

export const Input: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    field: {
      _placeholder: {
        color: 'grey.400',
      },
      _invalid: {
        borderColor: 'red.500',
        _hover: {
          borderColor: 'red.500',
        },
        _focusVisible: {
          borderColor: 'blue.500',
        },
      },
      _disabled: {
        borderColor: 'grey.300',
        bg: 'grey.50',
        _hover: {
          borderColor: 'grey.300',
        },
      },
    },
  },

  sizes: {
    default: {
      field: {
        height: '12',
        width: '100%',
        border: '0.1',
        borderRadius: 'sm-md',
        borderColor: 'grey.400',
        bg: 'white',
        py: '3',
        px: '4',
      },
    },
  },

  variants: {
    'republic-default': {
      field: { ...republicInputBaseStyles },
    },
    'republic-outline-large': (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Input.variants?.outline(props),
      field: {
        ...defaultTheme.components.Input.variants?.outline(props).field,
        ...defaultTheme.components.Input.sizes?.lg.field,
        ...republicInputBaseStyles,
        fontSize: '26px',
        border: '2px solid',
        _hover: {
          ...republicInputBaseStyles['_hover'],
          boxShadow: '0 4px 20px rgb(0 0 0 / 10%)',
        },
        _focusVisible: {
          ...republicInputBaseStyles['_focusVisible'],
          border: '2px solid',
          boxShadow: '0 4px 20px rgb(0 0 0 / 10%)',
        },
        py: '3',
        fontWeight: 'light',
        height: '14',
      },
    }),
    'republic-outline': (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Input.variants?.outline(props),
      field: {
        ...defaultTheme.components.Input.variants?.outline(props).field,
        ...defaultTheme.components.Input.sizes?.lg.field,
        ...republicInputBaseStyles,
        height: '10',
      },
    }),
    'republic-outline-small': (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Input.variants?.outline(props),
      field: {
        ...defaultTheme.components.Input.variants?.outline(props).field,
        ...defaultTheme.components.Input.sizes?.md.field,
        ...republicInputBaseStyles,
        height: '9',
      },
    }),
    'republic-outline-lite': (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Input.variants?.outline(props),
      field: {
        ...defaultTheme.components.Input.variants?.outline(props).field,
        ...defaultTheme.components.Input.sizes?.md.field,
        py: '3',
        px: '10px',
        height: '42px',
        borderRadius: 4,
        borderColor: 'grey.100',
        bg: 'white',
        color: 'grey.900',
        _hover: {
          borderColor: 'grey.200',
        },
        _active: {
          borderColor: 'blue.800',
        },
        _focusVisible: {
          boxShadow: 'none',
          borderColor: 'blue.500',
        },
        _focus: {
          borderColor: 'blue.500',
        },
        _invalid: {
          borderColor: 'red.500',
        },
        _valid: {
          borderColor: 'green.500',
        },
        _disabled: {
          borderColor: 'grey.200',
          bgColor: 'grey.50',
          cursor: 'not-allowed',
          _hover: {
            borderColor: 'grey.200',
          },
        },
      },
    }),
  },
  defaultProps: {
    variant: 'republic-default',
    size: 'default',
  },
});
