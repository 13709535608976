import {
  ErrorBoundary as ErrorBoundaryBase,
  IErrorBoundaryProps as IErrorBoundaryBaseProps,
} from '@r-client/shared/data/error-reporting';

import { ErrorMessage } from '../error-message/error-message';

export const ErrorBoundary = ({
  children,
  fallbackUI = ErrorMessage,
  callback,
}: IErrorBoundaryBaseProps) => {
  return (
    <ErrorBoundaryBase callback={callback} fallbackUI={fallbackUI}>
      {children}
    </ErrorBoundaryBase>
  );
};
