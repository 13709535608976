// This is a build time optimisation we require for development
/* eslint-disable no-process-env */
import { getLocalStorage } from '@r-client/shared/util/core';

// Variables should be computed without runtime. To ensure webpack can optimize it
export const MSW_POSSIBLE =
  process.env.STORYBOOK ||
  process.env.NODE_ENV === 'test' ||
  (process.env.NODE_ENV === 'development' && typeof window !== 'undefined');

export const isMSWEnabled = () =>
  !!process.env.STORYBOOK || getLocalStorage()?.getItem('enableMSW') === 'true';

export const isWebVitalsEnabled = () => {
  if (process.env.NODE_ENV === 'test') {
    return true;
  }
  return (
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    getLocalStorage()?.getItem('enableWebVitals') === 'true'
  );
};
