import { useEffect } from 'react';

import axios from 'axios';

import { useReporting } from '@r-client/shared/data/error-reporting';
import { typeCast } from '@r-client/shared/util/core';

const client = axios.create({
  baseURL: '/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const useBackendAnalyticsIntegration = (hasUser: boolean) => {
  const errorReporting = useReporting();

  useEffect(() => {
    const handleRouteChange = () => {
      client
        .post(`/r_client_session_post${window.location.search}`)
        .catch((err: unknown) => {
          errorReporting.error(
            'Failed to sync session with server',
            typeCast<Error>(err)
          );
        });
    };

    if (hasUser) {
      handleRouteChange();
    }
  }, [errorReporting, hasUser]);
};
