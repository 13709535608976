import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';

const grey = defineStyle({
  opacity: 1,
  [cssVar('skeleton-start-color').variable]: 'colors.grey.50',
  [cssVar('skeleton-end-color').variable]: 'colors.grey.50',
});

const grey100 = defineStyle({
  opacity: 1,
  [cssVar('skeleton-start-color').variable]: 'colors.grey.100',
  [cssVar('skeleton-end-color').variable]: 'colors.grey.100',
});

const baseStyle = defineStyle({
  [cssVar('skeleton-start-color').variable]: 'colors.grey.50',
  [cssVar('skeleton-end-color').variable]: 'colors.grey.100',
  borderRadius: 'xl',
});

export const Skeleton = defineStyleConfig({
  baseStyle,
  variants: {
    'republic-grey': grey,
    'republic-grey.100': grey100,
  },
});
