import backgroundColors from './background-color.module.scss';
import colors from './color.module.scss';

export type TColors =
  | 'black'
  | 'black80'
  | 'gray80'
  | 'gray70'
  | 'gray60'
  | 'gray40'
  | 'gray2'
  | 'gray4'
  | 'gray5'
  | 'gray6'
  | 'gray8'
  | 'gray9'
  | 'gray-e0'
  | 'gray20'
  | 'gray-f2'
  | 'gray-light'
  | 'white'
  | 'blue'
  | 'green'
  | 'error'
  | 'warning'
  | 'orchid'
  | 'disabled'
  | 'danger';

export const COLORS: Record<TColors, string> = colors as any;
export const BG_COLORS: Record<TColors, string> = backgroundColors as any;
