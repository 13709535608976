import { Context, createContext, FC, useEffect, useRef } from 'react';

import { buildContextHook, getWindow } from '@r-client/shared/util/core';

import { AnalyticsModel, IAnalyticsModel } from './models/analytics-model';
import { ISegment } from './plugins/segment';

const context = createContext<IAnalyticsModel>(new AnalyticsModel());
const DataProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const analytics = useRef(new AnalyticsModel());
  useEffect(() => {
    const segment = getWindow<{ analytics: ISegment }>()?.analytics;
    segment?.ready(() => {
      analytics.current.init();
    });
  }, []);
  return (
    <context.Provider value={analytics.current}>{children}</context.Provider>
  );
};

export const AnalyticsProvider: typeof DataProvider & {
  context: typeof context;
} = DataProvider as FC<React.PropsWithChildren<unknown>> & {
  context: Context<IAnalyticsModel>;
};

AnalyticsProvider.context = context;
AnalyticsProvider.displayName = 'AnalyticsProvider';

export const useAnalytics = buildContextHook<IAnalyticsModel>(
  context,
  'AnalyticsProvider'
);
