import { ComponentStyleConfig } from '@chakra-ui/react';

export const Badge: ComponentStyleConfig = {
  sizes: {
    'republic-small': {
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '16px',
      letterSpacing: '-0.12px',
      py: 1,
      px: 2,
      borderRadius: '4px',
    },
  },
};
