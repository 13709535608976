/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGetUserQuery,
  IGetUserQueryVariables,
  GetUserDocument,
} from './types';
import { IUser } from '@r-client/data/graphql-types';
export type { IUser };

export type IGetUserQueryOpts = IQueryModelOpts<IGetUserQueryVariables>;

export class GetUserQuery extends BaseQueryModel<
  IGetUserQuery,
  IGetUserQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGetUserQueryOpts) {
    super(opts);
  }

  get query() {
    return GetUserDocument;
  }
}
export function useGetUserQuery(opts?: Omit<IGetUserQueryOpts, 'client'>) {
  const client = useGqlClient();
  return useQuery(() => new GetUserQuery({ ...opts, client }), opts?.variables);
}
