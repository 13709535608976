export const TSIZES = ['lg', 'md', 'sm', 'xs'] as const;

export type TSizes = typeof TSIZES[number];
export type TSizePretty = Exclude<TSizes, 'lg'>;
export type TTypes<T extends string> = T | `${T}-${TSizePretty}`;
export type TAlign = 'center' | 'left' | 'right';

export type TMedia<T> = T | { [k in TSizes]?: T };

export function extract<T>(
  pr: (t: T, size: TSizes) => string | undefined,
  is: (t: TMedia<T>) => t is T
) {
  return (t: TMedia<T> | undefined) => {
    if (t === undefined) {
      return [];
    }
    if (is(t)) {
      return pr(t as any, 'lg') ?? [];
    } else {
      return TSIZES.reduce((memo, size) => {
        const val = t[size];
        if (val !== undefined) {
          const res = pr(val, size);
          if (res !== undefined) {
            memo.push(res);
          }
        }
        return memo;
      }, [] as string[]);
    }
  };
}

function isString<T extends string>(v: TMedia<T>): v is T {
  return typeof v === 'string';
}

function isBool(v: TMedia<boolean>): v is boolean {
  return typeof v === 'boolean';
}

export function getClassName<T extends string>(
  name: T,
  size: TSizes
): T | `${T}-${Exclude<TSizes, 'lg'>}` {
  const t = size === 'lg' ? (`${name}` as const) : (`${name}-${size}` as const);
  return t as any;
}

export function createBooleanGetter(
  styleGetter: (name: boolean, size: TSizes) => string | undefined
) {
  return extract<boolean>(styleGetter, isBool);
}

export function createStringGetter<T extends string>(
  styleGetter: (name: T, size: TSizes) => string | undefined
) {
  return extract<T>(styleGetter, isString);
}
