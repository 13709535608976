import { useMemo } from 'react';

import axios from 'axios';

export interface ISeedrsClientConfig {
  baseURL?: string;
}

export const createSeedrsClient = (config: ISeedrsClientConfig) => {
  if (!config.baseURL) {
    throw new Error('Republic Europe API base URL not provided');
  }
  return axios.create({
    baseURL: config.baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const useSeedrsClientManager = (config: ISeedrsClientConfig) => {
  const client = useMemo(() => createSeedrsClient(config), [config]);
  return client;
};
