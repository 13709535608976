import debug from 'debug';

import { getWindow } from '@r-client/shared/util/core';

import { TIdentity, TPage, TTrack } from '../models/interface';
import { ISegment } from '../plugins/segment';

const log = debug('data:analytics');

export interface IAnalyticsModel {
  identify(params: TIdentity): void;
  reset(): void;
  track(params: TTrack): void;
  page(params: TPage): void;
  init(): void;
}

export class AnalyticsModel implements IAnalyticsModel {
  private segment: ISegment | undefined;
  constructor() {
    this.segment = getWindow<{ analytics: ISegment }>()?.analytics;
  }

  // re-initiate if segment is not created during SSR/SSG pages
  init() {
    this.segment = getWindow<{ analytics: ISegment }>()?.analytics;
  }

  identify(params: TIdentity) {
    log('Analytics Segment identify: ', JSON.stringify(params));
    this.segment?.identify(params.userId, params?.traits, params?.options);
  }

  reset() {
    log('Analytics Segment reset: ');
    this.segment?.reset();
  }

  page(params: TPage) {
    if (this.segment === undefined) this.init();
    log('Analytics Segment page: ', JSON.stringify(params));
    // Enable params when needed.
    if (params.category) {
      this.segment?.page(params.category, params.name, params?.options);
    }
    this.segment?.page(params.name, params?.options);
  }

  track(params: TTrack) {
    log('Analytics Segment track: ', JSON.stringify(params));
    this.segment?.track(params.name, params?.params, params?.options);
  }
}
