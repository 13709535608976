import { useEffect } from 'react';

// Here we override NextError, so this is the only valid place to import it
// eslint-disable-next-line no-restricted-imports
import NextError, { ErrorProps } from 'next/error';

import { useReporting } from './reporter-context';
export { type ErrorProps };

const SKIP_STATUS_CODES = [404];

export const Error = ({ statusCode, title }: ErrorProps) => {
  const reporting = useReporting();
  useEffect(() => {
    // We do skip few status codes to report rollbar like 404
    // This Error component is handled by user to show error on certain use case.
    if (!SKIP_STATUS_CODES.includes(statusCode)) {
      reporting.error(`ErrorPage: [${statusCode}] ${title}`);
    }
  }, [reporting, statusCode, title]);
  return <NextError statusCode={statusCode} title={title}></NextError>;
};
