import { ApolloError } from '@apollo/client/errors';
import debug from 'debug';
import { makeAutoObservable } from 'mobx';

import { IAuthToken } from '@r-client/data/graphql-types';
import { IAnalyticsModel } from '@r-client/shared/data/analytics';
import {
  getExpirationCookie,
  GraphqlClient,
  isMobileWebView,
} from '@r-client/shared/data/client';
import { Reporting } from '@r-client/shared/data/error-reporting';
import {
  getLocalStorage,
  getWindow,
  TAuthPersistence,
} from '@r-client/shared/util/core';

import { AuthenticateWithTwoFactorMutation } from '../../graphql/authenticate-with-two-factor-mutation';
import { GetUserQuery } from '../../graphql/get-user-query';
import { SignInMutation } from '../../graphql/sign-in-mutation';
import { SignOutMutation } from '../../graphql/sign-out-mutation';
import {
  E_SIGN_IN_ERROR_CODES,
  Guest,
  IAuthModel,
  IAuthModelOpts,
  IAuthUser,
  ISignIn,
  ISignInResult,
  ISignOut,
  ITwoFactorSignIn,
  MaybeValue,
  User,
  Viewer,
} from './interface';

const log = debug('feature:auth');

const canSessionExist = (authPersistence?: TAuthPersistence) => {
  if (authPersistence?.type === 'localStorage') {
    return !!getLocalStorage()?.getItem('ra:token');
  }
  if (authPersistence?.type === 'cookie') {
    const cookieExists = !!getExpirationCookie(authPersistence.cookiePrefix);
    if (cookieExists) return true;

    // TODO: https://linear.app/republic/issue/PLAT-723/revert-webview-specific-r-client-auth-change
    // Until mobile adds the cookie, we'll assume the user is logged in and make the user query
    return isMobileWebView();
  }
  return false;
};

const createGuest = (): Guest => ({
  isAuthenticated: false,
  isAdmin: false,
  info: undefined,
});

const createUser = (info: NonNullable<IAuthUser>): User<IAuthUser> => ({
  isAuthenticated: true,
  isAdmin: info.isAdmin,
  info,
});

const twoFactorSignInToken = 'ra:two-factor-token';

export class AuthModel implements IAuthModel {
  private userQuery: GetUserQuery;
  private client: GraphqlClient;
  private analytics: IAnalyticsModel | undefined;
  private reporting: Reporting;
  private authPersistence: TAuthPersistence = { type: 'none' };
  private canSessionExist = false;

  constructor(opts: IAuthModelOpts) {
    makeAutoObservable(this);
    this.analytics = opts?.analytics;
    this.client = opts?.client;
    this.reporting = opts.reporting;
    this.authPersistence = opts.authPersistence;

    log('UserQuery initiated!');

    this.canSessionExist = canSessionExist(this.authPersistence);
    this.userQuery = new GetUserQuery({
      lazy: !this.canSessionExist,
      client: opts?.client,
      context: {
        doNotBatch: true,
      },
    });
  }

  /**
   * This reloads the user object for logged in user.
   */
  reloadSession = () => {
    log('UserQuery reload forcefully!');
    // This quirky logic is required to cover the case
    // that a query can be initiated in the `constructor`
    this.canSessionExist = true;
    this.userQuery.subscribe();
    return this.userQuery.refetch();
  };

  /**
   * Sign In user into his republic account.
   *
   * @param email is user's account email for signIn.
   * @param password is user's account password for signIn.
   * @param redirectTo to use to redirect user after successful sign in
   * @param source is which republic domain (retail/capital/...) to sign in
   * @param twoFactorRedirectTo is the url user will be redirected if two-factor authentication is required
   * @returns boolean true/false which refers success or failures.
   */
  signIn = async ({
    email,
    password,
    redirectTo,
    twoFactorRedirectTo = '/two-factor',
    source,
  }: ISignIn): Promise<ISignInResult> => {
    try {
      const mutation = new SignInMutation({
        client: this.client,
        variables: { email: email.toLowerCase(), password, source },
      });
      log('SignIn Initiated!');
      const result = await mutation.submit();

      if (
        result &&
        result.data?.signIn?.authFlags?.need2fa &&
        result.data.signIn.signInCode
      ) {
        getLocalStorage()?.setItem(
          twoFactorSignInToken,
          result.data.signIn.signInCode
        );

        if (redirectTo) {
          twoFactorRedirectTo = `${twoFactorRedirectTo}?redirectTo=${redirectTo}`;
        }

        getWindow<{ location: Location }>()?.location.assign(
          twoFactorRedirectTo
        );

        return {
          need2FA: true,
          success: true,
        };
      }

      if (result && result.data?.signIn?.authToken) {
        return this.processToken(
          result.data.signIn.authToken as IAuthToken,
          redirectTo
        );
      }

      let error;
      if (!result) {
        const mutationError = mutation.error as ApolloError;
        error = mutationError.graphQLErrors[0];
      } else {
        error = result.data?.signIn?.errors?.[0];
      }

      return {
        success: false,
        error: {
          message: error?.message || '',
          code: error?.extensions?.code || '',
        },
      };
    } catch (e) {
      log('Error reported to rollbar', e);
      this.reporting.error(e as Error);
      return { success: false };
    }
  };

  authenticateWithTwoFactor = async ({
    code,
    redirectTo,
  }: ITwoFactorSignIn): Promise<ISignInResult> => {
    const signInCode = getLocalStorage()?.getItem(twoFactorSignInToken);

    if (signInCode == null) {
      return {
        success: false,
        error: {
          code: E_SIGN_IN_ERROR_CODES.TWO_FACTOR_EXPIRED,
          message: 'Please sign in again',
        },
      };
    }

    const mutation = new AuthenticateWithTwoFactorMutation({
      client: this.client,
      variables: { code, signInCode },
    });
    log('Two factor Initiated!');
    try {
      const result = await mutation.submit();

      if (result && result.data?.authenticateWithTwoFactor?.authToken) {
        getLocalStorage()?.removeItem(twoFactorSignInToken);

        return await this.processToken(
          result.data.authenticateWithTwoFactor.authToken as IAuthToken,
          redirectTo
        );
      }

      let error;
      if (!result) {
        const mutationError = mutation.error as ApolloError;
        error = mutationError.graphQLErrors[0];
      } else {
        error = result.data?.authenticateWithTwoFactor?.errors?.[0];
      }

      return {
        success: false,
        error: {
          message: error?.message || '',
          code: error?.extensions?.code || '',
        },
      };
    } catch (e) {
      log('Error reported to rollbar', e);
      this.reporting.error(e as Error);
      return { success: false };
    }
  };

  /**
   * Sign Out user action.
   *
   * @param action is refers the location from where user is sign out.
   * @param redirectTo to use to redirect user after successful sing out
   * @returns boolean true/false which refers success or failures.
   */
  signOut = async ({ redirectTo }: ISignOut): Promise<boolean> => {
    try {
      const accessToken = getLocalStorage()?.getItem('ra:token');
      if (canSessionExist(this.authPersistence)) {
        const logData = this.viewer?.isAuthenticated
          ? { user: this.viewer.info.id }
          : {};
        this.reporting.info('User is being signed out from r-client.', logData);
        const mutation = new SignOutMutation({
          client: this.client,
          variables:
            this.authPersistence.type === 'localStorage' && !!accessToken
              ? { accessToken }
              : {},
        });
        const result = await mutation.submit();
        if (result) {
          this.reporting.info(
            'User having tokens removed from r-client.',
            logData
          );
          const wasReset = await this.resetUser();
          if (!wasReset) {
            return false;
          }

          // Redirecting to /logout would cause the admin that was just logged back in
          // to become logged out completely
          if (
            redirectTo === '/logout' &&
            result.data?.UserAuth_signOutUser?.isLoggedBackInAsAdmin
          ) {
            getWindow<{ location: Location }>()?.location.reload();
            return true;
          }

          if (redirectTo)
            getWindow<{ location: Location }>()?.location.assign(redirectTo);

          return true;
        }
        return false;
      }
      return false;
    } catch (e) {
      log('Error reported to rollbar', e);
      this.reporting.error(e as Error);
      return false;
    }
  };

  resetUser = async (): Promise<boolean> => {
    try {
      if (this.authPersistence.type === 'localStorage') {
        getLocalStorage()?.removeItem('ra:token');
        getLocalStorage()?.removeItem('ra:refresh');
        getLocalStorage()?.removeItem('ra:expiration');
      }
      this.analytics?.reset();
      this.userQuery.resetQuery();
      await this.client.clearStore();
    } catch (e) {
      log('Error reported to rollbar', e);
      this.reporting.error(e as Error);
      return false;
    }

    return true;
  };

  // TODO: update token processing or refactor
  private processToken = async (token: IAuthToken, redirectTo?: string) => {
    if (this.authPersistence.type === 'localStorage') {
      getLocalStorage()?.setItem('ra:token', token.accessToken);
      getLocalStorage()?.setItem('ra:refresh', token.refreshToken);
      if (token.expiresAt)
        getLocalStorage()?.setItem('ra:expiration', token.expiresAt);
      // Load forcefully the user query with new token
    }
    const userData = await this.reloadSession();
    log('SignIn success!', userData);
    const user = userData?.data.user;
    if (token.accessToken && user?.id && user?.email)
      this.analytics?.identify({
        userId: user.id,
        traits: {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        },
      });
    if (redirectTo)
      getWindow<{ location: Location }>()?.location.assign(redirectTo);
    return { success: true };
  };

  get viewer(): MaybeValue<Viewer> {
    if (!this.canSessionExist) return createGuest();
    if (!this.userQuery.data) return undefined;
    return this.userQuery.data.user
      ? createUser(this.userQuery.data.user)
      : createGuest();
  }

  get isLoading(): boolean {
    return this.userQuery.isLoading;
  }

  get errors() {
    return this.userQuery.queryErrors;
  }
}

export class AuthModelPrerender<TViewer = Viewer>
  implements IAuthModel<TViewer>
{
  viewer = undefined;
  isLoading = false;
  errors = undefined;

  constructor() {
    this.isLoading = true;
  }

  reloadSession() {
    return undefined;
  }

  signIn(): Promise<ISignInResult> {
    return Promise.resolve({ success: false });
  }

  authenticateWithTwoFactor(): Promise<ISignInResult> {
    return Promise.resolve({ success: false });
  }

  signOut(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
