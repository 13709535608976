import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const GQL_STATE_PROP_NAME = '__APOLLO_STATE__';

export type GqlClientStateProp = { __APOLLO_STATE__: NormalizedCacheObject };

export function withGqlClientState<Props extends Record<string, unknown>>(
  client: ApolloClient<NormalizedCacheObject>,
  props: Props
): Props & GqlClientStateProp {
  return { ...props, [GQL_STATE_PROP_NAME]: client.cache.extract() };
}
