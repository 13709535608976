import React, {
  Context,
  createContext,
  FunctionComponent,
  useContext,
} from 'react';

import { useLocalObservable } from 'mobx-react-lite';

export const buildContextHook =
  <T>(contextProvider: Context<T>, contextTitle: string) =>
  () => {
    const context = useContext<T>(contextProvider);
    if (!context) {
      throw new Error(`context must be used within a ${contextTitle}`);
    }
    return context;
  };

export const createProvider = <TModel extends Record<string, any>>(
  displayName: string,
  context = createContext<TModel>(null as any)
) => {
  const Provider: FunctionComponent<
    React.PropsWithChildren<{ model: TModel }>
  > = ({ children, model }) => {
    const value = useLocalObservable(() => model);
    return React.createElement(context.Provider, { value }, children);
  };
  Provider.displayName = displayName;
  (Provider as any).context = context;
  const useData = buildContextHook<TModel>(context, displayName);
  return { Provider, useData };
};
