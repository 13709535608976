import { useMemo } from 'react';

import { NormalizedCacheObject } from '@apollo/client';
import isObject from 'lodash/isObject';

import { createGqlClient } from './data-client';
import { IGqlClientConfig, TReporting } from './types';
import {
  GQL_STATE_PROP_NAME,
  GqlClientStateProp,
} from './with-gql-client-state';

export function useGqlManager(
  pageProps: unknown,
  clientConfig: IGqlClientConfig,
  reporting?: TReporting
) {
  const state: undefined | NormalizedCacheObject = isObject(pageProps)
    ? (pageProps as GqlClientStateProp)[GQL_STATE_PROP_NAME]
    : undefined;
  const client = useMemo(
    () =>
      createGqlClient({ initialState: state, config: clientConfig, reporting }),
    [state, clientConfig, reporting]
  );
  return client;
}
