// eslint-disable-next-line @nx/enforce-module-boundaries
import { ComponentStyleConfig, StyleFunctionProps } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';

export const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: 'grey.900',
      _disabled: {
        borderColor: 'grey.300',
        bg: 'grey.50',
        _hover: {
          borderColor: 'grey.300',
        },
      },
    },
  },
  sizes: {
    default: {
      field: {
        height: '12',
        width: '100%',
        border: '0.1',
        borderRadius: 'sm-md',
        borderColor: 'grey.400',
        bg: 'white',
        py: '3',
        pl: '4',
        pr: '2.5',
      },
    },
  },
  variants: {
    'republic-outline': (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Select.variants?.outline(props),
      ...defaultTheme.components.Select.sizes?.md,
      field: {
        ...defaultTheme.components.Select.variants?.outline(props).field,

        borderColor: 'brand.grey.e0',
        _hover: { boxShadow: '0 4px 20px rgb(0 0 0 / 10%)' },
        _active: {
          borderColor: 'brand.primary',
          boxShadow: '0 0 0 1px #0049ff',
        },
        _disabled: {
          bg: 'white',
        },
        _focusVisible: {
          borderColor: 'brand.primary',
          boxShadow: '0 0 0 1px #0049ff',
        },
        background: 'brand.white',
        borderRadius: 'md',
        height: '10',

        border: '1px',
        ...defaultTheme.components.Select.sizes?.md.field,
        pb: '1px',
        pt: 0,
      },
      icon: {
        '> svg': {
          fill: 'brand.grey.95',
        },
      },
    }),
    'republic-outline-lite': (props: StyleFunctionProps) => {
      return {
        ...defaultTheme.components.Select.variants?.outline(props),
        field: {
          ...defaultTheme.components.Select.variants?.outline(props).field,
          pl: '10px',
          pr: '12px',
          height: '42px',
          bg: 'white',
          borderColor: 'grey.100',
          borderRadius: '4px',
          outline: 'none',
          fontSize: 'md',
          lineHeight: 'md',
          fontWeight: 400,
          letterSpacing: 'less-tight',
          option: {
            color: 'black',
          },
          '> option[value=""]': {
            display: 'none',
          },
          _active: {
            borderColor: 'brand.blue.c6',
          },
          _hover: {
            borderColor: 'grey.200',
            boxShadow: 'none',
          },
          _focusVisible: {
            borderColor: 'blue.500',
            boxShadow: 'none',
          },
          _disabled: {
            bg: 'grey.50',
            borderColor: 'grey.200',
          },
          _valid: {
            borderColor: 'green.500',
          },
          _invalid: {
            borderColor: 'red.500',
          },
        },
        icon: {
          w: '18px',
          h: '18px',
          '> svg': {
            w: '18px',
            h: '18px',
            color: 'grey.400',
          },
        },
      };
    },
  },
  defaultProps: {
    variant: 'republic-outline',
    size: 'default',
  },
};
