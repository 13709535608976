import React, { forwardRef, ReactNode } from 'react';

import classNames from 'clsx';

import { dataRole } from '@r-client/shared/util/core';

import styles from './container.module.scss';

export interface IContainerProps {
  className?: string;
  role?: string;
  pageContent?: boolean;
  fluid?: boolean;
  withoutPaddings?: boolean;
  children: ReactNode;
}

/** @deprecated
 * Please use Chakra UI's Container instead component instead.
 */
export const Container = forwardRef<HTMLDivElement, IContainerProps>(
  function Container(
    { children, className, role, pageContent, fluid, withoutPaddings },
    ref
  ) {
    const cn = classNames([
      styles.container,
      className,
      {
        [styles.pageContent]: !!pageContent,
        [styles.fluid]: !!fluid,
        [styles.withoutPaddings]: !!withoutPaddings,
      },
    ]);

    return (
      <div ref={ref} className={cn} {...dataRole(role)}>
        {children}
      </div>
    );
  }
);
