import React, { FunctionComponent, useMemo } from 'react';

import CN from 'clsx';

import {
  brm2px,
  createBooleanGetter,
  createStringGetter,
  CssBuilder,
  CssBuilderInline,
  dataRole,
  getClassName,
  getClassNameByValue,
  TAlign,
  TIndent,
  TMedia,
  TSizeSpacing,
  TSizeWithBrm,
  TVal,
  useConfig,
} from '@r-client/shared/util/core';

import styles from './box.module.scss';

type TColor =
  | 'white'
  | 'gray-f2'
  | 'light-blue'
  | 'danger'
  | 'black'
  | 'gray-f7'
  | 'gray-e0'
  | 'gray-78';

type TSizeInherit = TSizeSpacing | 'inherit';

export interface IBoxProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'hidden'> {
  className?: string;
  as?: keyof JSX.IntrinsicElements; // tag 'div' by default
  m?: TVal<TIndent>; // margin
  p?: TVal<TIndent>; // padding
  w?: TVal<TSizeInherit>; // width
  h?: TVal<TSizeInherit>; // height
  bg?: TVal<TColor>; // background
  borderRadius?: TVal<
    '2' | '3' | '4' | '5' | '6' | '8' | '10' | '12' | '13' | '16' | '24'
  >; // border-radius
  role?: string;
  hidden?: TMedia<boolean>;
  textAlign?: TMedia<TAlign>;
  zIndex?: TVal<string>;
}

function indentFormat(v: TSizeWithBrm | number): TSizeWithBrm {
  return typeof v === 'number' ? (`${v}brm` as const) : v;
}

const top = (v: TSizeWithBrm | number): `${TSizeWithBrm} 0 0 0` =>
  `${indentFormat(v)} 0 0 0` as const;
const right = (v: TSizeWithBrm | number): `0 ${TSizeWithBrm} 0 0` =>
  `0 ${indentFormat(v)} 0 0` as const;
const bottom = (v: TSizeWithBrm | number): `0 0 ${TSizeWithBrm} 0` =>
  `0 0 ${indentFormat(v)} 0` as const;
const left = (v: TSizeWithBrm | number): `0 0 0 ${TSizeWithBrm}` =>
  `0 0 0 ${indentFormat(v)}` as const;
const getHidden = createBooleanGetter((name, size) => {
  if (!name) {
    return undefined;
  }
  const key = getClassName('hidden', size);
  return (styles as Record<string, string>)[key];
});
const getAlign = createStringGetter<TAlign>((name, size) => {
  const key = getClassName(`${name}` as const, size);
  return (styles as Record<string, string>)[key];
});
const getZindex = createStringGetter<string>((name, size) => {
  const key = getClassName(`${name}` as const, size);
  return (styles as Record<string, string>)[key];
});

const BoxComponent: FunctionComponent<React.PropsWithChildren<IBoxProps>> =
  function Box({
    className,
    p,
    m,
    bg,
    borderRadius,
    hidden,
    textAlign,
    zIndex,
    ...params
  }) {
    const config = useConfig();
    const props: React.PropsWithChildren<IBoxPropsFix> = {
      ...params,
      p: brm2px(p),
      m: brm2px(m),
      className: CN(
        className,
        getClassNameByValue('bg', bg, styles),
        getClassNameByValue('r', borderRadius, styles),
        getHidden(hidden),
        getAlign(textAlign),
        getZindex(zIndex)
      ),
    };
    return config.cssVarsSupport ? BoxCssVariables(props) : BoxInline(props);
  };

/**
 * @deprecated
 * please use Box from "@chakra-ui/react" instead.
 *
 * Note: eslint rules from dls-coding-standards do not apply to this legacy component
 */
export const Box: typeof BoxComponent & {
  top: typeof top;
  left: typeof left;
  right: typeof right;
  bottom: typeof bottom;
} = BoxComponent as any;
Object.assign(Box, { top, left, right, bottom });

interface IBoxPropsFix
  extends Omit<IBoxProps, 'p' | 'm' | 'bg' | 'borderRadius'> {
  m?: TVal<string>; // margin
  p?: TVal<string>; // padding
}

let counter = 0;
function BoxInline(props: React.PropsWithChildren<IBoxPropsFix>) {
  const {
    as = 'div',
    m,
    p,
    w,
    h,
    className,
    children,
    role,
    ...params
  } = props;
  const cssName = useMemo(() => `box-${counter++}`, []);
  const b = new CssBuilderInline(cssName, { ...params, m, p, w, h });
  const el = React.createElement(
    as,
    {
      className: CN(className, b.classNames),
      ...dataRole(role),
      ...params,
    },
    children
  );
  return (
    <>
      <style
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: b.styleTag }}
      />
      {el}
    </>
  );
}

function BoxCssVariables(props: React.PropsWithChildren<IBoxPropsFix>) {
  const {
    as = 'div',
    m,
    p,
    w,
    h,
    className,
    children,
    style,
    ...params
  } = props;
  const b = new CssBuilder(styles, { ...params, m, p, w, h });
  return React.createElement(
    as,
    {
      className: CN(className, b.classNames),
      style: { ...style, ...b.style },
      ...params,
    },
    children
  );
}
