import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const legacyBaseStyle = definePartsStyle({
  control: {
    width: '18px',
    height: '18px',
    border: '1px solid',
    borderColor: 'brand.grey.c7',
    _checked: {
      bg: 'brand.primary',
      borderColor: 'brand.primary',
      _before: {
        width: 2,
        height: 2,
      },
      _hover: {
        borderColor: 'brand.blue.34',
        bgColor: 'brand.blue.34',
      },
    },
    _hover: {
      borderColor: 'brand.blue.71',
      _checked: {
        bg: 'brand.blue.34',
        _disabled: {
          bg: 'brand.blue.9f',
          borderColor: 'brand.blue.9f',
          _before: {
            bg: 'white',
          },
        },
        _active: {
          borderColor: 'brand.blue.c6',
          bgColor: 'brand.blue.c6',
        },
      },
    },
    _active: {
      bg: 'brand.blue.00',
      borderColor: 'brand.blue.00',
      _before: {
        content: '""',
        width: 2,
        height: 2,
        bg: 'white',
        borderRadius: '50%',
      },
    },
    _disabled: {
      borderColor: 'brand.grey.9d',
      bg: 'white',
      _checked: {
        bg: 'brand.blue.9f',
        borderColor: 'brand.blue.9f',
        _before: {
          bg: 'white',
        },
      },
    },
  },
  label: {
    marginLeft: '10px',
    fontWeight: 'normal',
    fontSize: 'md',
    lineHeight: 'md',
    letterSpacing: 'less-tight',
    color: 'black',
    _disabled: {
      color: 'black',
      opacity: 1,
    },
  },
});

const baseStyle = definePartsStyle({
  control: {
    w: '5',
    h: '5',
    border: '0.1',
    _checked: {
      _focusVisible: {
        bg: 'blue.500',
        borderColor: 'blue.500',
      },
      _hover: {
        bg: 'blue.200',
        borderColor: 'blue.200',
      },
    },
    _disabled: {
      borderColor: 'grey.300',
      _checked: {
        bg: 'blue.200',
        color: 'blue.50',
      },
      _hover: {
        borderColor: 'grey.300',
      },
      _focusVisible: {
        borderColor: 'grey.300',
      },
    },
    _invalid: {
      borderColor: 'red.500',
    },
  },
});

const buttonVariant = definePartsStyle({
  control: {
    display: 'none',
  },
  label: {
    margin: 0,
    textTransform: 'capitalize',
    color: 'inherit',
    letterSpacing: 'less-tight',
    fontWeight: 400,
    _disabled: {
      color: 'grey.300',
      opacity: 1,
    },
  },
  container: {
    color: 'black',
    borderRadius: 'lg',
    padding: '10px 24px',
    bg: 'white',
    boxShadow: 'inset 0 0 0 1px #c7c7c7',
    _checked: {
      color: 'blue.500',
      borderColor: 'blue.500',
      boxShadow: 'inset 0 0 0 1px #0049FF',
      _disabled: {
        color: 'rgba(26, 26, 26, 0.3)',
        boxShadow: 'inset 0 0 0 1px #e0e0e0',
      },
    },
    _hover: {
      borderColor: 'grey.200',
      color: 'blue.500',
      boxShadow: 'inset 0 0 0 1px #0049FF',
      _disabled: {
        color: 'grey.300',
        boxShadow: 'inset 0 0 0 1px #e0e0e0',
      },
    },
  },
});

export const Radio = defineMultiStyleConfig({
  baseStyle,
  variants: {
    'republic-button': buttonVariant,
    'republic-default': legacyBaseStyle,
  },
});
