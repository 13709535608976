import { Reporting } from '@r-client/shared/data/error-reporting';
import { getWindow } from '@r-client/shared/util/core';

import { IAuthModel } from '../models/interface';

export const parseToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export function signOutFromSearchParams(
  authModel: IAuthModel,
  reporting: Reporting
) {
  const currentLocation = getWindow<{ location: Location }>()?.location.href;
  if (!currentLocation) return;

  const url = new URL(currentLocation);
  const signedOut = url.searchParams.get('signed_out');

  if (signedOut !== 'true') return;

  const logData = authModel.viewer?.isAuthenticated
    ? { user: authModel.viewer.info.id }
    : {};
  reporting.info(`User is being signed out due to search param.`, logData);

  url.searchParams.delete('signed_out');
  getWindow<{ history: History }>()?.history.replaceState(null, '', url);
  authModel.signOut({});
}
