export const breakpoints = {
  // viewport size + 1 (mobile first); read as "min. size for viewport"
  base: '0px',
  xs: '431px',
  sm: '769px',
  md: '970px',
  lg: '1171px',
  xl: '1441px',
  '2xl': '1921px',
};
