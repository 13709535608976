/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IRefreshTokenMutationVariables = Types.Exact<{
  refreshToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IRefreshTokenMutation = {
  __typename?: 'Mutation';
  Jwt_refreshToken: {
    __typename?: 'AuthRefreshPayload';
    token?:
      | {
          __typename?: 'AuthToken';
          accessToken: string;
          refreshToken: string;
          expiresAt?: string | null | undefined;
        }
      | null
      | undefined;
    errors?:
      | Array<{ __typename?: 'ValidationError'; message: string }>
      | null
      | undefined;
  };
};

export const RefreshTokenDocument = gql`
  mutation refreshToken($refreshToken: String) {
    Jwt_refreshToken(refreshToken: $refreshToken) {
      token {
        accessToken
        refreshToken
        expiresAt
      }
      errors {
        message
      }
    }
  }
`;
export type IRefreshTokenMutationFn = Apollo.MutationFunction<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<IRefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>;
