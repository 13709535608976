import React, { FunctionComponent } from 'react';

import classNames from 'clsx';

import { dataRole, useTheme } from '@r-client/shared/util/core';

import styles from './pseudo-link.module.scss';

enum E_VARIANTS {
  muted = 'muted',
  unstyled = 'unstyled',
  underline = 'underline',
  white = 'white',
  lite = 'lite',
  rightArrow = 'rightArrow',
  inheritedUnderline = 'inheritedUnderline',
}

export interface IPseudoLinkProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: E_VARIANTS | E_VARIANTS[];
  inherit?: boolean;
  role?: string;
}

const PseudoLink: FunctionComponent<
  React.PropsWithChildren<IPseudoLinkProps>
> = ({ children, className, inherit, variant, role, ...props }) => {
  const { theme } = useTheme();

  const variantClasses =
    !!variant &&
    (Array.isArray(variant)
      ? variant.map((variantName) => styles[variantName])
      : styles[variant]);
  return (
    <button
      {...props}
      className={classNames(className, styles.pseudoLink, variantClasses, {
        [styles.inherit]: inherit,
        [styles.wl]: theme === 'WL',
      })}
      type="button"
      {...dataRole(role)}
    >
      {children}
    </button>
  );
};

export const LinkComponent = PseudoLink as typeof PseudoLink & {
  variants: typeof E_VARIANTS;
};
LinkComponent.variants = E_VARIANTS;
