import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
const md = defineStyle({
  height: 5,
  width: 5,
});

const primary = defineStyle({
  color: 'blue.700',
});

export const Spinner = defineStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
  variants: { primary },
  sizes: { md },
});
