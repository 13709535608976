import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { makeObservable, runInAction } from 'mobx';

export interface IAxiosQueryModelOpts<TVariables> {
  variables: TVariables;
  client: AxiosInstance;
}

export abstract class AxiosQueryModel<
  TData,
  TVariables = Record<string, never>
> {
  client: AxiosInstance;
  response: AxiosResponse<TData> | undefined;
  error: AxiosError | undefined;

  variables: TVariables | undefined;
  state: 'pending' | 'error' | 'done' = 'pending';

  constructor(opts: IAxiosQueryModelOpts<TVariables>) {
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      client: false,
      fetch: true,
      value: true,
      setVariables: true,
      variables: true,
      state: true,
      response: true,
      error: true,
    });
    this.client = opts.client;
    this.variables = opts.variables;
  }

  protected abstract query(): Promise<AxiosResponse<TData>>;

  async fetch() {
    this.response = undefined;
    this.error = undefined;
    this.state = 'pending';
    try {
      const response = await this.query();
      runInAction(() => {
        this.state = 'done';
        this.response = response;
      });
    } catch (error: unknown) {
      runInAction(() => {
        if (axios.isAxiosError(error)) {
          this.error = error;
        }
        this.state = 'error';
      });
    }
  }

  get value() {
    if (this.state === 'done') {
      return this.response?.data;
    }
    return undefined;
  }

  setVariables(variables: TVariables | undefined) {
    this.variables = variables;
  }
}

export const makeAxiosQuery = <TData>(
  query: () => Promise<AxiosResponse<TData>>
) => {
  const Implementation = class extends AxiosQueryModel<TData> {
    query() {
      return query();
    }
  };

  return new Implementation({ variables: {}, client: axios.create() });
};
