import { setContext } from '@apollo/client/link/context';

import { getWindow } from '@r-client/shared/util/core';

import { requestRefreshAccessToken } from '../refresh-request';
import {
  E_REFRESH_TOKEN_STATUS,
  IClientOpts,
  IGqlClientConfig,
  TRefreshTokenResult,
  TReporting,
} from '../types';
import {
  getExpirationCookie,
  isTokenExpired,
  isTokenExpiringSoon,
  redirectAfterInvalidation,
} from './auth-refresh-helpers';

let refreshTokenInitiated = false;
let refreshTokenInFly: Promise<TRefreshTokenResult> | undefined = undefined;

async function getRefreshedAccessToken({
  apiUri,
  apiAuthHeader,
  authCookiePrefix,
  reporting,
}: Pick<IGqlClientConfig, 'apiAuthHeader' | 'apiUri'> & {
  authCookiePrefix: string | undefined;
  reporting?: TReporting;
}) {
  const window = getWindow();
  function onBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    reporting?.debug('Refresh token request was interrupted by page unload');
    return (event.returnValue = '');
  }

  if (window) window.addEventListener('beforeunload', onBeforeUnload);

  const result = await requestRefreshAccessToken({
    apiUri,
    apiAuthHeader,
    reporting,
  });
  if (window) window.removeEventListener('beforeunload', onBeforeUnload);

  refreshTokenInitiated = false;
  refreshTokenInFly = undefined;

  // Auth successfully responded, but with an error => Refresh token is invalid
  if (result.status === E_REFRESH_TOKEN_STATUS.Error) {
    const expiration = getExpirationCookie(authCookiePrefix);

    if (!expiration || isTokenExpired(expiration)) {
      const location = getWindow<{ location: Location }>()?.location;
      redirectAfterInvalidation(
        `/logout?after_sign_out=${location?.href}`,
        reporting
      );
    }
  }

  return result;
}

export const createAuthCookieLink = ({
  authCookiePrefix,
  apiUri,
  apiAuthHeader,
  reporting,
}: Pick<IClientOpts, 'apiUri' | 'apiAuthHeader' | 'reporting'> & {
  authCookiePrefix: string | undefined;
}) => {
  return setContext(async () => {
    const expirationCookie = getExpirationCookie(authCookiePrefix);

    if (
      expirationCookie &&
      isTokenExpiringSoon(expirationCookie) &&
      !refreshTokenInitiated
    ) {
      refreshTokenInitiated = true;
      refreshTokenInFly = getRefreshedAccessToken({
        apiUri,
        apiAuthHeader,
        authCookiePrefix,
        reporting,
      });
    }
    await refreshTokenInFly;
  });
};
