import { selectURI } from '@apollo/client';
import { Operation } from '@apollo/client/link/core/types';
import uniqueId from 'lodash/uniqueId';

export type TOperationContext = {
  batchGroup?: string;
  doNotBatch?: true;
};

const getUniqueBatchGroup = () => uniqueId('batchGroup_');

const getBatchGroup = ({
  doNotBatch,
  batchGroup,
}: TOperationContext): string | undefined => {
  if (doNotBatch === true) return getUniqueBatchGroup();
  if (batchGroup) return batchGroup;
  return;
};

// See the default function:
// https://github.com/apollographql/apollo-client/blob/main/src/link/batch-http/batchHttpLink.ts#L192-L206
export const createBatchKeyBuilder =
  (apiUri: string) => (operation: Operation) => {
    const context = operation.getContext() as TOperationContext &
      ReturnType<Operation['getContext']>;

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
      batchGroup: getBatchGroup(context),
    };

    //may throw error if config not serializable
    return selectURI(operation, apiUri) + JSON.stringify(contextConfig);
  };
