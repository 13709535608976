import { ApolloQueryResult } from '@apollo/client/core/types';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { FrontendApi, Identity } from '@ory/client';
import { AxiosError, AxiosInstance } from 'axios';

import { IAnalyticsModel } from '@r-client/shared/data/analytics';
import { GraphqlClient } from '@r-client/shared/data/client';
import { Reporting } from '@r-client/shared/data/error-reporting';
import { TAuthPersistence } from '@r-client/shared/util/core';

import { IGetUserQuery, ISignInMutationVariables } from '../../graphql/types';
import { ISeedrsUserStatusResult } from './seedrs-user-query-model';

export type MaybeValue<T> = T | undefined;

export type IAuthUser = IGetUserQuery['user'];

export type User<TUser = IAuthUser> = {
  isAuthenticated: true;
  isAdmin: boolean;
  info: NonNullable<TUser>;
};

export type UserWithOptionalInfo<TUser = IAuthUser> = {
  isAuthenticated: true;
  isAdmin: boolean;
  info: TUser | Record<string, never>;
};

export type Guest = {
  isAuthenticated: false;
  isAdmin: false;
  info: undefined;
};

export type Viewer = Guest | User<IAuthUser>;

export type SeedrsUser = User<ISeedrsUserStatusResult['result']>;

export type SeedrsViewer = Guest | SeedrsUser;

export type GlobalUser = UserWithOptionalInfo<Identity>;
export type GlobalViewer = Guest | GlobalUser;

export interface IAuthModel<TViewer = Viewer> {
  /**
   * The `Viewer` is a union type for two product types:
   * - The `User` type (represents a registered and authenticated user)
   * - The `Guest` type (represents an unregistered or unauthenticated user)
   *
   * Usage:
   * 1. `viewer.isAuthenticated` to check if the current user is authenticated.
   * 2. `viewer.isAdmin` to check if the current user is an admin.
   * 3. `viewer.info` to access the user's personal information (available only for authenticated users).
   *
   * Note that the `viewer` object is undefined until the userQuery has been fulfilled
   * for the first time. However, it is defined when the query is being refetched.
   */
  viewer: MaybeValue<TViewer>;
  isLoading: boolean;
  errors: GraphQLErrors | ApolloError | AxiosError | undefined;
  reloadSession: () => Promise<ApolloQueryResult<IGetUserQuery>> | undefined;
  signIn: (opts: ISignIn) => Promise<ISignInResult>;
  authenticateWithTwoFactor: (opts: ITwoFactorSignIn) => Promise<ISignInResult>;
  signOut: (opts: ISignOut) => Promise<boolean>;
}

export interface IGlobalAuthModel extends IAuthModel<GlobalViewer> {
  ory: FrontendApi;
}

export interface IGlobalAuthModelOpts {
  globalAuthBaseUrl: string;
}

export interface IAuthModelOpts {
  analytics?: IAnalyticsModel;
  client: GraphqlClient;
  reporting: Reporting;
  authPersistence: TAuthPersistence;
}

export interface ISeedrsAuthModelOpts {
  analytics?: IAnalyticsModel;
  client: AxiosInstance;
  reporting: Reporting;
}

export interface ISignIn extends ISignInMutationVariables {
  redirectTo?: string;
  twoFactorRedirectTo?: string;
}

export interface ITwoFactorSignIn {
  redirectTo?: string;
  code: string;
}

export enum E_SIGN_IN_ERROR_CODES {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  DOWNSTREAM_SERVICE_ERROR = 'DOWNSTREAM_SERVICE_ERROR',

  TWO_FACTOR_EXPIRED = 'TWO_FACTOR_EXPIRED',
}
export interface ISignInResult {
  success: boolean;
  need2FA?: boolean;
  error?: {
    message: string;
    code: string;
  };
}

export enum E_SIGN_OUT_ACTION {
  USER = 'USER',
}
export interface ISignOut {
  action?: E_SIGN_OUT_ACTION;
  redirectTo?: string;
}
