import { getWindow } from './global';
const getCookies = () => getWindow()?.document.cookie || '';

/**
 *
 * @param key
 * @returns string | undefined
 * Returns the value of a cookie by name.
 */
export const getCookie = (key: string) => {
  return getCookies()
    .split('; ')
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
};

export type SetCookie = {
  name: string;
  value: string;
  options?: {
    maxAge?: number;
    sameSite?: 'strict' | 'lax' | 'none';
    secure?: boolean;
    domain?: string;
  };
};

/**
 *
 * @param {name, value, options}: SetCookie
 * @returns void
 * Sets a cookie with the given name, value and options.
 */
export const setCookie = ({ name, value, options }: SetCookie) => {
  const window = getWindow();
  if (!window) return;

  const {
    maxAge = 604800,
    sameSite = 'Strict',
    secure = true,
    // eslint-disable-next-line no-process-env
    domain = process.env.NEXT_PUBLIC_TLD,
  } = options || {};

  const cookie = `${name}=${value}; max-age=${maxAge}; SameSite=${sameSite}; Path=/; Secure=${secure}; Domain=${domain}`;
  window.document.cookie = cookie;
};

/**
 *
 * @param name
 * @returns void
 * Deletes a cookie by name by setting its expiration date to the past.
 */
export const deleteCookie = (name: string) => {
  const window = getWindow();
  if (!window) return;

  window.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};
