import { createContext, useContext } from 'react';

import { AxiosInstance } from 'axios';

export const SeedrsClientContext = createContext<AxiosInstance>(
  undefined as any
);

export const useSeedrsClient = () =>
  useContext<AxiosInstance>(SeedrsClientContext);
