import type { Debugger } from 'debug';
import { autorun } from 'mobx';

import type { IFieldModel } from './';
import { ValidationError } from './validation-error';

export class FieldsBaseHelper<
  TKey extends string,
  T extends Record<TKey, IFieldModel<any>>
> implements Omit<IFieldModel<{ [K in keyof T]: T[K]['value'] }>, 'id'>
{
  readonly fields: T;
  constructor(fields: T) {
    this.fields = fields;
  }

  static checkErrorsThrowable<
    TKey extends string,
    T extends Record<TKey, IFieldModel<any>>
  >(helper: FieldsBaseHelper<TKey, T>, log?: Debugger): void {
    let error: string | undefined;
    let busy = false;

    autorun(() => {
      error = helper.error;
      busy = helper.busy;
      if (error) {
        log?.('Errors map', helper.errorsMap);
      }
    })();
    if (error) {
      throw new ValidationError(error);
    }
    if (busy) {
      throw new ValidationError('Validation in progress');
    }
  }

  reduce<TObj>(
    memo: TObj,
    fn: (memo: TObj, field: IFieldModel<T[TKey]['value']>, name: TKey) => TObj
  ) {
    const keys = Object.keys(this.fields) as TKey[];
    return keys.reduce((m, name) => fn(m, this.fields[name], name), memo);
  }

  map<TR>(fn: (field: IFieldModel<T[TKey]['value']>, key: TKey) => TR) {
    return this.reduce<TR[]>([], (memo, field, key) => {
      const val = fn(field, key);
      memo.push(val);
      return memo;
    });
  }

  get value() {
    const keys = Object.keys(this.fields) as TKey[];
    const res = keys.reduce((memo, name: TKey) => {
      memo[name] = this.fields[name].value;
      return memo;
    }, {} as { [K in keyof T]: T[K]['value'] });
    return res;
  }

  set value(val) {
    const keys = Object.keys(this.fields) as TKey[];
    keys.forEach((name) => {
      this.fields[name].value = val[name];
    });
  }

  get errorsMap() {
    return this.reduce(
      {} as Record<TKey, string | undefined>,
      (memo, field, name) => {
        memo[name] = field.error;
        return memo;
      }
    );
  }

  get error() {
    return this.map((field) => field.error).find((p) => p);
  }

  set error(val) {
    this.map((field) => {
      field.error = val;
    });
  }

  set errors(errorMap: { [K in keyof T]: T[K]['error'] }) {
    const keys = Object.keys(this.fields) as TKey[];
    keys.forEach((name) => {
      this.fields[name].error = errorMap[name];
    });
  }

  get isValid() {
    return this.map((field) => field.isValid).every((p) => p);
  }

  get isDirty() {
    return this.map((field) => field.touched).some((p) => p);
  }

  get touched() {
    return this.map((field) => field.touched).every((p) => p);
  }

  set touched(val: boolean) {
    this.map((field) => (field.touched = val));
  }

  get changing() {
    return !!this.map((field) => field.changing).find((v) => v);
  }

  get busy() {
    return !!this.map((field) => field.busy).find((v) => v);
  }

  resetErrors() {
    const keys = Object.keys(this.fields) as TKey[];
    keys.forEach((name) => {
      this.fields[name].error = undefined;
    });
  }

  setupTouched() {
    this.touched = true;
  }

  getError() {
    return Promise.all(
      this.map((field: IFieldModel<T[TKey]['value']>) => {
        const fn = field.getError;
        if (fn instanceof Function) {
          return fn.call(field);
        }
        return undefined;
      })
    ).then((values) => values.find((p) => p));
  }
}
