/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  ISignOutMutation,
  SignOutDocument,
  ISignOutMutationVariables,
} from './types';
import { IAuthSignOutPayload } from '@r-client/data/graphql-types';
export type { IAuthSignOutPayload };

export class SignOutMutation extends BaseMutationModel<
  ISignOutMutation,
  ISignOutMutationVariables
> {
  get mutation() {
    return SignOutDocument;
  }
}
export function useSignOutMutation(
  opts?: Omit<IMutationModelOpts<ISignOutMutationVariables>, 'client'>
) {
  const client = useGqlClient();
  return useLocalObservable(() => new SignOutMutation({ ...opts, client }));
}
