import {
  FetchResult,
  MutationOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { GraphQLError } from 'graphql/error';
import { action, computed, makeObservable, observable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { RemoveTNullProperties } from '@r-client/shared/util/core';
import {
  FieldsBaseHelper,
  IFieldModel,
  IFieldModelRecord,
} from '@r-client/shared/util/model';

import { GraphqlClient } from './data-client';
import { getUserInputErrors, toValidationErrors } from './mutation-error';

export interface IMutationModelOpts<
  TVariables extends OperationVariables | undefined = Record<string, unknown>
> extends Omit<MutationOptions, 'mutation'> {
  fields?: Required<IFieldModelRecord<RemoveTNullProperties<TVariables>>> &
    Partial<IFieldModelRecord<TVariables>>;
  variables?: TVariables;
  client: GraphqlClient;
}

type ISubmit<T> = {
  /** By default on submit all form elements are set touched.
   * This flag lets you skip this step. */
  skipTouched?: boolean;
  isValid?: boolean;
  variables?: T;
};

export abstract class BaseMutationModel<
  TData,
  TVariables extends OperationVariables | undefined = Record<string, unknown>
> {
  /** Observable promise in case you need extended information about mutation status
   * See docs for reference https://github.com/mobxjs/mobx-utils#frompromise
   */
  public mutationHandle:
    | IPromiseBasedObservable<FetchResult<TData>>
    | undefined = undefined;
  private mutationErrors: unknown;
  private client: GraphqlClient;
  protected abstract get mutation(): TypedDocumentNode<TData, TVariables>;

  public readonly form: FieldsBaseHelper<
    string,
    Record<string, IFieldModel<any>>
  >;
  private opts: IMutationModelOpts<TVariables> | undefined;

  constructor(opts: IMutationModelOpts<TVariables>) {
    this.opts = opts;
    this.client = opts.client;
    this.form = new FieldsBaseHelper({ ...opts?.fields });
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable<
      BaseMutationModel<TData, TVariables>,
      'exec' | 'mutationErrors'
    >(this, {
      mutationHandle: observable.ref,
      values: computed,
      isValid: computed,
      exec: action,
      mutationErrors: observable,
      error: computed,
    });
  }

  private async exec(variables?: TVariables) {
    this.mutationErrors = undefined;
    this.mutationHandle = fromPromise(
      this.client.mutate({
        fetchPolicy:
          'network-only' /** to execute mutation on every invoke so fresh request to server will make */,
        ...this.opts,
        variables: {
          ...this.form.value,
          ...this.opts?.variables,
          ...variables,
        },
        mutation: this.mutation,
      })
    );
  }

  /** this event is for get all form fields to used with form elements example Input etc...  */
  get fields():
    | Record<keyof TVariables, IFieldModel<any>>
    | Record<string, IFieldModel<any>> {
    return this.form.fields;
  }

  /** this event to get error obj of mutationErrors */
  get error() {
    return this.mutationErrors;
  }

  /** this event to get values object for form fields */
  get values(): Record<string, IFieldModel<any> | never> {
    return this.form.value;
  }

  /** this event to get status of form for validation on fields */
  get isValid(): boolean {
    return this.form.isValid;
  }

  /** observable which provides form submission status */
  get isLoading(): boolean {
    return this.mutationHandle?.state === 'pending';
  }

  async submit(
    opts?: ISubmit<TVariables>
  ): Promise<FetchResult<TData> | false> {
    try {
      if (this.isValid && (opts?.isValid || opts?.isValid === undefined)) {
        this.exec(opts?.variables);
        const result = await this.mutationHandle!;
        if (result.errors) {
          this.setFieldsErrors(result.errors);
          this.mutationErrors = result.errors;
        }
        return result;
      }
    } catch (err) {
      this.mutationErrors = err;
      return false;
    } finally {
      if (!opts?.skipTouched) this.form.setupTouched();
    }
    return false;
  }

  private setFieldsErrors(errors: readonly GraphQLError[]) {
    const noFields = Object.keys(this.fields).length < 1;
    if (noFields) return;

    const userInputErrors = getUserInputErrors(toValidationErrors(errors));

    this.form.resetErrors();
    this.form.errors = userInputErrors;
  }
}
