// This is the only module where we import rollbar and deps
// eslint-disable-next-line no-restricted-imports

// eslint-disable-next-line no-restricted-imports
import type Rollbar from 'rollbar';
// eslint-disable-next-line no-restricted-imports
export type { MaybeError } from 'rollbar';

export * from './config';
export * from './reporter-context';
export * from './navigation-context-handler';
export { Error, type ErrorProps } from './error';
export { MockErrorReportingProvider } from './mock-error-reporting-provider';
export { ErrorBoundary } from './error-boundary';
export type { IErrorBoundaryProps } from './error-boundary';
export type Reporting = Rollbar;
export { createErrorReporting } from './create-error-reporting';

export interface IConfigurationSettings {
  /**
   * API integration token. if no token passed no reporting provided
   */
  accessToken: string | undefined;
  environment: 'production' | 'staging';
  codeVersion: string | undefined;
  serverHost: string;
  /**
   * kind is self control key not to miss configuration update on vendor change
   */
  kind: 'rollbar';
}

export type RollbarConfiguration = Rollbar.Configuration;
