import { getCookie, getWindow } from '@r-client/shared/util/core';

import { TReporting } from '../types';

const ONE_MINUTES_MILLISECONDS = 60000;

export function redirectAfterInvalidation(
  redirectTo: string,
  reporting?: TReporting
) {
  const location = getWindow<{ location: Location }>()?.location;

  reporting?.debug('Invalidating token', { location: location?.href });
  location?.assign(redirectTo);
}

const prefixedName = (prefix: string | undefined, name: string) =>
  prefix ? `${prefix}-${name}` : name;

export const getExpirationCookie = (prefix: string | undefined) => {
  return getCookie(prefixedName(prefix, 'republic-auth-expiration'));
};

export function isTokenExpired(expiresAt: string | number) {
  const expiresAtTime = new Date(+expiresAt).getTime();

  return expiresAtTime < Date.now();
}

export function isTokenExpiringSoon(expiresAt: string | number) {
  const expiresAtTime = new Date(+expiresAt).getTime();

  return expiresAtTime - Date.now() < ONE_MINUTES_MILLISECONDS;
}

export const isMobileWebView = () => {
  return !!getCookie('mobile_app_version');
};
