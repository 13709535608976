import { FakeReporter } from './fake-reporter';
import { Reporter } from './reporter';

export const createErrorReporting = (
  options?: Reporter.Configuration | undefined
) => {
  if (
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV !== 'production' ||
    // eslint-disable-next-line no-process-env
    process.env.STORYBOOK ||
    !options?.accessToken
  ) {
    return FakeReporter.init({ accessToken: 'fakeAccessToken' });
  }
  return new Reporter(options);
};
