import { ComponentStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'semibold',
    display: 'flex',
    lineHeight: 'tall',
    justifyContent: 'space-between',
    marginInlineEnd: '0',
    mb: '0',
  },
  sizes: {
    large: {
      fontSize: '2xl',
      lineHeight: '2rem',
      letterSpacing: 'widest',
    },
  },
  variants: {
    republic: {
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: 'md',
      letterSpacing: 'less-tight',
      color: 'grey.800',
      mb: '2',
    },
  },
  defaultProps: {
    size: 'default',
  },
};
