// https://chakra-ui.com/docs/styled-system/theming/customize-theme
// eslint-disable-next-line @nx/enforce-module-boundaries
import { extendTheme } from '@chakra-ui/react';

// import { dls as dlsTheme } from '@r-client/dls/ui/core';
// COMPONENTS:
import { Alert } from './components/alert';
import { Avatar } from './components/avatar';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { FormLabel } from './components/form-label';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Popover } from './components/popover';
import { Progress } from './components/progress';
import { Radio } from './components/radio';
import { Select } from './components/select';
import { Skeleton } from './components/skeleton';
import { Spinner } from './components/spinner';
import { Switch } from './components/switch';
import { Tabs } from './components/tabs';
import { Text } from './components/text';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip';
// FOUNDATIONS:
import { radii } from './foundations/border-radius';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { layerStyles } from './foundations/layer-styles';
import { semanticTokens } from './foundations/semantic-tokens';
import { space } from './foundations/spacing';
import {
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  textStyles,
} from './foundations/typography';

export * from './utilities/px-to-number';

export const legacyTheme = extendTheme({
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  layerStyles,
  radii,
  semanticTokens,
  space,
  textStyles,
  components: {
    Alert,
    Avatar,
    Badge,
    Button,
    Checkbox,
    FormLabel,
    Heading,
    Input,
    Link,
    Modal,
    Popover,
    Progress,
    Radio,
    Select,
    Skeleton,
    Spinner,
    Switch,
    Tabs,
    Text,
    Textarea,
    Tooltip,
  },
});

export const theme = extendTheme(legacyTheme);
// export const theme = extendTheme(legacyTheme, dlsTheme);

export const embeddedTheme = extendTheme(theme, {
  styles: {
    global: {
      'html, body': {
        height: 'auto',
        overflow: 'hidden',
      },
    },
  },
});
