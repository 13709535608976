import React from 'react';

import CN from 'clsx';
import NextLink from 'next/link';

import {
  CHROMATIC_IGNORE,
  isSpaUrl,
  useTheme,
} from '@r-client/shared/util/core';

import type { IButtonBaseProps } from './button-ui-component';

import styles from './button.module.scss';

export interface IButtonProps extends IButtonBaseProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  success?: boolean;
  rightArrow?: boolean;
  underline?: boolean;
  wide?: boolean;
  wideBorder?: boolean;
  responsive?: boolean;
  icon?: React.ReactNode;
  iconOnly?: boolean;
  size?: 'small' | 'tiny' | 'extraLarge';
  shape?: 'circle' | 'circle-outline' | 'round';
  squareCorners?: 'left' | 'right';
  target?: '_self' | '_blank' | '_parent' | 'top';
  forIcon?: boolean;
  shadow?: 'none' | 'default' | 'inset';
  linkColor?: 'blue' | 'gray60'; // only for links
}

/**
 * @deprecated
 * please use `Button` from `@chakra-ui/react` instead.
 */
export const Button = React.forwardRef<HTMLElement, IButtonProps>(
  function Button(
    {
      className,
      children,
      onClick,
      fullWidth,
      fullHeight,
      rightArrow,
      underline,
      wide,
      wideBorder,
      responsive,
      target,
      type = 'default',
      size,
      forIcon,
      linkColor = 'blue',
      icon,
      iconOnly,
      href,
      shadow,
      squareCorners,
      shape,
      loading,
      success,
      danger,
      ...props
    },
    ref
  ) {
    const { theme } = useTheme();
    const classNames =
      theme === 'republic'
        ? CN(styles.main, className, {
            [styles.primary]: type === 'primary',
            [styles.default]: type === 'default',
            [styles.defaultThin]: type === 'default-thin',
            [styles.ghost]: type === 'ghost',
            [styles.grayBackgroundLink]: type === 'grayBackgroundLink',
            [styles.link]: type === 'link',
            [styles.borderedBlue]: type === 'bordered-blue',
            [styles.success]: success,
            [styles.danger]: danger,
            [styles.loading]: loading,
            [CHROMATIC_IGNORE]: loading,
            [styles.fullWidth]: fullWidth,
            [styles.fullHeight]: fullHeight,
            [styles.responsive]: responsive,
            [styles.rightArrow]: rightArrow,
            [styles.underline]: underline,
            [styles.wideBorder]: wideBorder,
            [styles.wide]: !!wide,
            [styles.small]: size === 'small',
            [styles.gray60]: linkColor === 'gray60',
            [styles.blue]: linkColor === 'blue',
            [styles.forIcon]: forIcon,
            [styles.withIcon]: !!icon,
            [styles.iconOnly]: iconOnly,
            [styles.squareCornersLeft]: squareCorners === 'left',
            [styles.squareCornersRight]: squareCorners === 'right',
            [styles.tiny]: size === 'tiny',
            [styles.extraLarge]: size === 'extraLarge',
            [styles.shapeCircle]: shape === 'circle',
            [styles.shapeRound]: shape === 'round',
            [styles.shadow]: shadow === 'default',
            [styles.shadowInset]: shadow === 'inset',
            [styles.noShadow]: shadow === 'none',
          })
        : CN(styles.wl, className, {
            'c-button': type !== 'link',
            'c-button--': type === 'primary',
            'c-button--secondary': type === 'default',
            'c-button--responsive': responsive,
            'c-button--block': fullWidth,
            [styles.link]: type === 'link',
          });
    const isSPA = isSpaUrl(href);
    const buttonChildren = (
      <>
        {icon ? <div className={styles.icon}>{icon}</div> : null}
        {children}
      </>
    );
    const btnComponent = React.createElement('button', {
      type,
      ref,
      className: classNames,
      onClick,
      children: buttonChildren,
      ...props,
    });
    const anchorComponent = React.createElement('a', {
      href,
      type,
      ref,
      className: CN(classNames, styles.anchor),
      onClick,
      children: buttonChildren,
      target,
      ...props,
    });

    // If the button is given a link, it is an anchor tag styled
    // exactly the same as a button.
    // TODO: add ticket to migrate it later
    return href && !props.disabled ? (
      isSPA ? (
        <NextLink legacyBehavior href={href}>
          {anchorComponent}
        </NextLink>
      ) : (
        anchorComponent
      )
    ) : (
      btnComponent
    );
  }
);
