export const layerStyles = {
  form: {
    bgColor: 'grey.50',
    color: 'grey.900',
  },
  dashed: {
    borderRadius: 3,
    border: '1px dashed',
    borderColor: 'blue.200',
    opacity: '0.5',
  },
  scrollbarAlwaysVisible: {
    '::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: { base: '1', sm: '2' },
      zIndex: 'banner',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: { base: 0, sm: 'base' },
      backgroundColor: { base: 'rgba(0,0,0,.3)', sm: 'rgba(0,0,0,.5)' },
      zIndex: 'banner',
    },
  },
};
