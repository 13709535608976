import { Configuration, FrontendApi, Session } from '@ory/client';
import debug from 'debug';

import { AxiosQueryModel, makeAxiosQuery } from '@r-client/shared/data/client';
import { getWindow } from '@r-client/shared/util/core';

import {
  GlobalUser,
  GlobalViewer,
  Guest,
  IAuthModel,
  IGlobalAuthModel,
  IGlobalAuthModelOpts,
  ISignInResult,
  MaybeValue,
} from './interface';

const log = debug('feature:global-auth');

const createGuest = (): Guest => ({
  isAuthenticated: false,
  isAdmin: false,
  info: undefined,
});

const createUser = (session: Session): GlobalUser => ({
  isAuthenticated: true,
  isAdmin: false,
  info: session.identity ?? {},
});

export const isGlobalAuth = (
  model: IAuthModel<unknown>
): model is GlobalAuthModel => {
  return 'ory' in model;
};

export class GlobalAuthModel implements IGlobalAuthModel {
  private _ory: FrontendApi;
  private sessionQuery: AxiosQueryModel<Session>;

  constructor(opts: IGlobalAuthModelOpts) {
    this._ory = new FrontendApi(
      new Configuration({
        basePath: opts.globalAuthBaseUrl,
        baseOptions: {
          withCredentials: true,
        },
      })
    );

    log('GlobalAuthModel initialized');

    this.sessionQuery = makeAxiosQuery(() => this.ory.toSession());
    this.sessionQuery.fetch();
  }

  get ory(): FrontendApi {
    return this._ory;
  }

  reloadSession = () => {
    log('Session reload forcefully!');
    this.sessionQuery.fetch();
    return undefined;
  };

  get viewer(): MaybeValue<GlobalViewer> {
    if (!this.sessionQuery.value) return undefined;
    return this.sessionQuery.value.id
      ? createUser(this.sessionQuery.value)
      : createGuest();
  }

  get isLoading(): boolean {
    return this.sessionQuery.state === 'pending';
  }

  get errors() {
    return this.sessionQuery.error;
  }
  get session(): MaybeValue<Session> {
    return this.sessionQuery.value;
  }

  /** @deprecated going to be removed due to global auth integration
   * please review https://www.ory.sh/docs/guides/authentication
   */
  signIn = (): Promise<ISignInResult> => {
    throw new Error(
      'Method not implemented. And going to be removed due to global auth integration'
    );
  };

  /** @deprecated going to be removed due to global auth integration
   * please review https://www.ory.sh/docs/guides/authentication
   */
  signOut = () => {
    throw new Error(
      'Method not implemented. And going to be removed due to global auth integration'
    );
  };

  private redirect = (url: string) => {
    const location = getWindow()?.location;
    if (location) {
      location.assign(url);
    }
  };

  /** @deprecated going to be removed due to global auth integration
   * please review https://www.ory.sh/docs/guides/authentication
   */
  authenticateWithTwoFactor = (): Promise<ISignInResult> => {
    throw new Error(
      'Method not implemented. And going to be removed due to global auth integration'
    );
  };
}
