import { IFlipperEnum } from '@r-client/data/graphql-types';
import { useGetFeatureToggleQuery } from '@r-client/shared/data/flags/remote';

export const useBrazeFeatureFlag = () => {
  const { data, isLoading } = useGetFeatureToggleQuery({
    variables: {
      feature: IFlipperEnum.WebSdkBraze,
    },
  });

  return !isLoading && data?.flipper.enabled;
};
