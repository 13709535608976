import { ReactNode } from 'react';

// We break the rule here as this the place where we want to expose the Provider component
// eslint-disable-next-line no-restricted-imports
import { Provider } from '@rollbar/react';

import { FakeReporter } from './fake-reporter';
import { Reporter } from './reporter';

export function MockErrorReportingProvider({
  children,
  instance,
}: {
  children: ReactNode;
  instance?: Reporter | undefined;
}) {
  return (
    <Provider
      instance={
        instance ?? FakeReporter.init({ accessToken: 'fakeAccessToken' })
      }
    >
      {children}
    </Provider>
  );
}
