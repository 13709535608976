import React from 'react';

// We break the rule here as this the place where we want to expose the ErrorBoundary component
// eslint-disable-next-line no-restricted-imports
import {
  ErrorBoundary as ErrorBoundaryBase,
  ErrorBoundaryProps as ErrorBoundaryBaseProps,
} from '@rollbar/react';

export * from './config';
export * from './reporter-context';
export * from './navigation-context-handler';
export { Error } from './error';
export { MockErrorReportingProvider } from './mock-error-reporting-provider';

export interface IErrorBoundaryProps
  extends Omit<ErrorBoundaryBaseProps, 'fallbackUI'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fallbackUI?: React.ComponentType<any>;
}

export const ErrorBoundary = ({
  fallbackUI,
  ...restProps
}: IErrorBoundaryProps) => {
  return <ErrorBoundaryBase {...restProps} fallbackUI={fallbackUI} />;
};
