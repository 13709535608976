import type { Assign, Primitive, UnionToIntersection } from 'utility-types';
/**
 * Universal wrapper for virtual containers
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class $<T> {}

export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}

export type RemoveTNull<Type, TNull = undefined> = {
  [Key in keyof Type]: TNull extends Type[Key] ? never : Key;
}[keyof Type];

export type RemoveTNullProperties<Type, TNull = undefined> = {
  [Key in RemoveTNull<Type, TNull>]: Type[Key];
};

type TAll$<T> = {
  [P in keyof T]: any; // T[P]['$$tval'];
};

type TType$<T, TType, TNull = undefined> = RemoveTNullProperties<
  {
    [P in keyof TAll$<T>]: TAll$<T>[P] extends TType ? TAll$<T>[P] : undefined;
  },
  TNull
>;

type TValue<
  TDef,
  K extends Record<string, any>,
  T extends Partial<Record<K[keyof K]['name'], any>> = Record<
    K[keyof K]['name'],
    TDef
  >
> = {
  [P in K[keyof K]['name']]?: T[K[keyof K]['name']];
};

type TTypeValue<T, TType> = TValue<TType, Pick<T, keyof TType$<T, TType>>>;

type Ex<T> = TTypeValue<T, string> &
  TTypeValue<T, boolean> &
  TTypeValue<T, number>;

export type ExtractTypes<T> = {
  Values: $<Ex<T>>;
  $RealValues: Ex<T>;
  Keys: keyof Ex<T>;
  T: T;
  Name: any; //T[keyof T]['name'];
};

export type TypeFilter<
  TTypeLongName,
  TLeftType = TTypeLongName,
  TRightType = TTypeLongName
> = TTypeLongName extends string
  ? TLeftType
  : TTypeLongName extends boolean
  ? TLeftType
  : TTypeLongName extends number
  ? TLeftType
  : TRightType;

export type TGetValues<T> = T[keyof T];

export type PickProps<T, TName extends keyof T> = Pick<T, TName>[TName];

export type UnpackedArray<T> = T extends (infer U)[] ? U : T;

export type ReturnTypePromise<T extends (...args: any) => any> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export type UnwrapPromise<T1> = (() => T1) extends () => Promise<infer R>
  ? R
  : any;

export type ToUndefined<T> = {
  [P in keyof T]: T[P] | undefined;
};

type Loop<T> = {
  [P in keyof T]: T[P] extends Primitive
    ? T[P]
    : ReUndefined<UnionToIntersection<T[P]>>;
};

export type ReUndefined<T> = UnionToIntersection<
  Assign<
    Loop<RemoveTNullProperties<T>>,
    ToUndefined<Loop<Required<Omit<T, keyof RemoveTNullProperties<T>>>>>
  >
>;

export type UnPromisify<T> = T extends Promise<infer U> ? U : T;

export type Maybe<T> = null | undefined | T;

// Nominal typing helper
export abstract class ID<T> {
  abstract _type: T;
}
// Keep in mind that it should calculate id and compared as value
export const getID = <T>(entity: T, hash: (entity: T) => any): ID<T> => {
  return hash(entity) as ID<T>;
};

export class Enum<TType extends string> implements Iterable<TType> {
  readonly type: TType = null as any;
  readonly list: TType[];
  readonly enum: { [key in TType]: key } = {} as any;

  constructor(...enumList: TType[]) {
    this.list = enumList;
    enumList.forEach((val) => (this.enum[val] = val));
  }

  [Symbol.iterator]() {
    return this.list[Symbol.iterator]();
  }

  is(val: string | null | undefined): val is TType {
    if (val === undefined || val === null) {
      return false;
    }
    return Object.prototype.hasOwnProperty.call(this.enum, val);
  }

  get(
    val: string | null | undefined,
    def: TType | ((val: string | null | undefined) => TType)
  ): TType {
    return this.is(val) ? val : def instanceof Function ? def(val) : def;
  }
}

export function isString(val: unknown): val is string {
  return typeof val === 'string';
}
export function isStringOrUndefined(val: unknown): val is string | undefined {
  return val === undefined || isString(val);
}

export function typeCast<T>(t: unknown): T {
  return t as T;
}

export function isSpaUrl(url = '/'): boolean {
  if (url.startsWith('#')) return false;
  if (url.startsWith('//')) return false;
  if (url.match(/^[^/?]*?:/)) return false;
  if (url.match(/^.*?[/?].*?:/)) return true;
  if (!url.includes(':') && !url.includes('/')) return true;
  return !!url.match(/^.*?\//);
}

export function getUrlFirstPathSegment(url = '/'): string | undefined {
  const match = url.match(/^[^/]*(\/\/)?[^/]*\/([^/?#]*)/);
  return match ? match[2] : undefined;
}

export function getFirstSlug(slug: string | string[]): string {
  return Array.isArray(slug) ? slug[0] : slug;
}

export const getStringValue = (v: Maybe<string>) => v ?? '';

export const isInitialQueryLoading = ({
  data,
  isLoading,
}: {
  data: unknown;
  isLoading: boolean;
}) => isLoading && !data;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U> ? Array<Value<U>> : Value<T[P]>;
};

type AllowedPrimitives = boolean | string | number | Date;
type Value<T> = T extends AllowedPrimitives ? T : RecursivePartial<T>;

export function toAbsoluteURL(originUrl: string, path: string) {
  const origin = originUrl.endsWith('/') ? originUrl.slice(0, -1) : originUrl;
  return `${origin}${path}`;
}

export const isRepublicEmail = (email: string | undefined | null): boolean => {
  if (!email) {
    return false;
  }

  const regex = new RegExp('[A-Za-z0-9._%+-]+@republic.(?:com|co)$');

  return regex.test(email);
};

/*
 *  parseJSON quietly returns undefined if input is unparsable
 */
export const parseJSON = (input: string) => {
  try {
    return JSON.parse(input);
  } catch {
    return undefined;
  }
};
