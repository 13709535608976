/* eslint-disable no-console, @typescript-eslint/no-unused-vars */

// eslint-disable-next-line no-restricted-imports
import type Rollbar from 'rollbar';

const FAKE_UUID = { uuid: 'fake' };

let global_reporter: FakeReporter;
export class FakeReporter implements Rollbar {
  options: Rollbar.Configuration = {};

  constructor(options: Rollbar.Configuration = {}) {
    this.options = options;
    return;
  }
  static init(options: Rollbar.Configuration) {
    if (!global_reporter) {
      global_reporter = new FakeReporter(options);
    }
    return global_reporter;
  }

  static setComponents(components: Rollbar.Components) {
    return;
  }

  public get rollbar(): Rollbar {
    return this as Rollbar;
  }

  global(options: Rollbar.Configuration): Rollbar {
    return FakeReporter.init(options) as Rollbar;
  }

  configure(options: Rollbar.Configuration): Rollbar {
    console.log('ERROR REPORTING - configure', options);
    this.options = { ...this.options, ...options };
    return this as Rollbar;
  }

  lastError() {
    return null;
  }

  log(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - log', ...args);
    return FAKE_UUID;
  }
  debug(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - debug', ...args);
    return FAKE_UUID;
  }
  info(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - info', ...args);
    return FAKE_UUID;
  }
  warn(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - warn', ...args);
    return FAKE_UUID;
  }
  warning(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - warning', ...args);
    return FAKE_UUID;
  }
  error(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - error', ...args);
    return FAKE_UUID;
  }
  critical(...args: Rollbar.LogArgument[]) {
    console.log('ERROR REPORTING - critical', ...args);
    return FAKE_UUID;
  }
  wait(callback: () => void) {
    return;
  }

  captureEvent(metadata: Record<string, unknown>, level: Rollbar.Level) {
    console.log('captureEvent', metadata, level);
    return {} as Rollbar.TelemetryEvent;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  lambdaHandler<T = object>(handler: Rollbar.LambdaHandler<T>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return {} as Rollbar.LambdaHandler<T, any, any>;
  }

  errorHandler() {
    return {} as Rollbar.ExpressErrorHandler;
  }
}
