import { switchAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  StyleFunctionProps,
} from '@chakra-ui/react';
import { calc, cssVar } from '@chakra-ui/theme-tools';

export interface StyleFunctionPropsExtended extends StyleFunctionProps {
  noContent?: boolean;
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
const $diff = cssVar('switch-track-diff');

const diffValue = calc.subtract($width, $height);
const $translateX = cssVar('switch-thumb-x');

const $fontSize = cssVar('switch-track-font-size');
const $fontWeight = cssVar('switch-track-font-weight');
const $lineHeight = cssVar('switch-track-line-height');
const $letterSpacing = cssVar('switch-track-letter-spacing');
const $left = cssVar('switch-track-left');

const sizes = {
  'republic-sm': definePartsStyle({
    container: {
      [$width.variable]: '3.125rem',
      [$height.variable]: '1.25rem',
      [$fontSize.variable]: '12px',
      [$lineHeight.variable]: '16px',
      [$fontWeight.variable]: '700',
      [$letterSpacing.variable]: '-0.75em',
      [$left.variable]: '7px',
    },
  }),
  'republic-md': {
    container: {
      [$width.variable]: '4.25rem',
      [$height.variable]: '1.75rem',
      [$fontSize.variable]: '16px',
      [$lineHeight.variable]: '25px',
      [$fontWeight.variable]: '600',
      [$letterSpacing.variable]: 'less-tight',
      [$left.variable]: '11px',
    },
  },
};

const baseStyles = {
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  },
  track: {
    width: [$width.reference],
    height: [$height.reference],
    _before: {
      fontSize: [$fontSize.reference],
      fontWeight: [$fontWeight.reference],
      lineHeight: [$lineHeight.reference],
      right: [$left.reference],
      _checked: {
        left: [$left.reference],
        right: 'auto',
      },
    },
  },
  thumb: {
    width: [$height.reference],
    height: [$height.reference],
    _checked: {
      transform: `translateX(${$translateX.reference})`,
    },
  },
};

const republicBaseStyles = (
  isBlueColorSchema: boolean,
  hasContent: boolean
) => ({
  ...baseStyles.track,
  position: 'relative',
  bgColor: 'brand.grey.88',
  _hover: {
    bgColor: 'brand.grey.b3',
  },
  _active: {
    bgColor: 'brand.grey.80',
  },
  _checked: {
    bgColor: isBlueColorSchema ? 'blue.500' : 'brand.green.300',
    _hover: {
      bgColor: isBlueColorSchema ? 'blue.400' : 'brand.green.200',
    },
    _active: {
      bgColor: isBlueColorSchema ? 'blue.600' : 'brand.green.600',
    },
    _disabled: {
      bgColor: 'brand.grey.b3',
    },
    _before: {
      ...baseStyles.track._before._checked,
      content: hasContent ? '"Yes"' : '""',
    },
  },
  _disabled: {
    bgColor: 'brand.grey.c7',
  },
  _before: {
    ...baseStyles.track._before,
    position: 'absolute',
    content: hasContent ? '"No"' : '""',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'white',
  },
});

export const Switch = defineMultiStyleConfig({
  sizes,
  variants: {
    republic: (props: StyleFunctionPropsExtended) => {
      const isBlueColorSchema = props.colorScheme === 'blue';

      return {
        ...baseStyles,
        track: republicBaseStyles(isBlueColorSchema, true),
      };
    },
    'republic-no-content': (props: StyleFunctionPropsExtended) => {
      const isBlueColorSchema = props.colorScheme === 'blue';

      return {
        ...baseStyles,
        track: {
          ...republicBaseStyles(isBlueColorSchema, false),
        },
      };
    },
  },
});
