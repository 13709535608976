/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  ISignInMutation,
  SignInDocument,
  ISignInMutationVariables,
} from './types';
import { IAuthSignInPayload } from '@r-client/data/graphql-types';
export type { IAuthSignInPayload };

export class SignInMutation extends BaseMutationModel<
  ISignInMutation,
  ISignInMutationVariables
> {
  get mutation() {
    return SignInDocument;
  }
}
export function useSignInMutation(
  opts?: Omit<IMutationModelOpts<ISignInMutationVariables>, 'client'>
) {
  const client = useGqlClient();
  return useLocalObservable(() => new SignInMutation({ ...opts, client }));
}
