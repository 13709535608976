const TMAP = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',
} as const;

const TMAP_KEYS = Object.keys(TMAP) as Array<keyof typeof TMAP>;

export enum E_SIZE {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
}
const TTYPE = [E_SIZE.LG, E_SIZE.MD, E_SIZE.SM, E_SIZE.XS] as const;

export type TIndent = TIndentVarious<TSizeWithBrm> | 'inherit';

export type TIndentVarious<T extends string> =
  | T
  | `${T} ${T}`
  | `${T} ${T} ${T}`
  | `${T} ${T} ${T} ${T}`;
export type TSizeSpacing = '0' | `${number}px` | `${number}%` | 'auto';
export type TSizeWithBrm = TSizeSpacing | `${number}brm`;

export type TType = typeof TTYPE[number];
export type TBase = keyof typeof TMAP;
export type TSize = Exclude<TType, 'lg'>;
export type TTypesSpacing = TBase | `${TBase}-${TSize}`;

export type TVal<T> = T | { [k in TType]?: T };

export function isTValParam<T>(val: TVal<T>): val is { [k in TType]?: T } {
  return (
    typeof val === 'object' &&
    (Object.prototype.hasOwnProperty.call(val, 'lg') ||
      Object.prototype.hasOwnProperty.call(val, 'md') ||
      Object.prototype.hasOwnProperty.call(val, 'sm') ||
      Object.prototype.hasOwnProperty.call(val, 'xs'))
  );
}

export class CssBuilderInline {
  private css: Record<TType, string[]> = {
    lg: [],
    md: [],
    sm: [],
    xs: [],
  };
  public readonly name: string;
  private readonly props: Partial<Record<TBase, TVal<string>>>;

  constructor(name: string, props: Partial<Record<TBase, TVal<string>>>) {
    this.name = name;
    this.props = props;
    TMAP_KEYS.forEach((shortName) => {
      const prop = this.props[shortName];
      if (prop === undefined) {
        return;
      }
      const cssProp = TMAP[shortName];
      if (isTValParam(prop)) {
        TTYPE.forEach((size) => {
          const p = prop[size];
          if (p) {
            this.css[size].push(`${cssProp}:${p}`);
          }
        });
      } else {
        this.css.lg.push(`${cssProp}:${prop}`);
      }
    });
  }

  get classNames(): string[] {
    return TTYPE.map((size) =>
      this.css[size].length
        ? size === E_SIZE.LG
          ? this.name
          : `${this.name}-${size}`
        : ''
    ).filter((p) => p);
  }

  get styleTag() {
    const html: string[] = [];
    const { lg, md, xs, sm } = this.css;
    if (lg.length) {
      html.push(`.${this.name}{${lg.join(';')}}`);
    }
    if (md.length) {
      html.push(
        `@media all and (max-width: 1170px) {.${this.name}-md{${md.join(';')}}}`
      );
    }
    if (sm.length) {
      html.push(
        `@media all and (max-width: 969px) {.${this.name}-sm{${md.join(';')}}}`
      );
    }
    if (xs.length) {
      html.push(
        `@media all and (max-width: 767px) {.${this.name}-xs{${xs.join(';')}}}`
      );
    }
    return html.join('\n');
  }
}

export class CssBuilder {
  constructor(
    styles: Partial<Record<TTypesSpacing, string>>,
    props: Partial<Record<TBase, TVal<string>>>
  ) {
    this.styles$ = styles;
    this.props = props;
  }

  private styles$: Partial<Record<TTypesSpacing, string>>;
  private readonly props: Partial<Record<TBase, TVal<string>>>;
  private readonly var_prefix = '--sp-';

  get classNames(): string[] {
    return TMAP_KEYS.reduce((memo, k) => {
      const v = this.props[k];
      if (v) {
        if (isTValParam(v)) {
          return TTYPE.reduce((memoResult, name) => {
            if (!v[name]) {
              return memoResult;
            }
            const cssName =
              name === E_SIZE.LG
                ? (`${k}` as const)
                : (`${k}-${name}` as const);
            const s = this.styles$[cssName];
            if (s) {
              memoResult.push(s);
            }
            return memoResult;
          }, memo);
        } else {
          const s = this.styles$[k];
          if (s) {
            memo.push(s);
          }
        }
      }
      return memo;
    }, [] as string[]);
  }

  get style() {
    return TMAP_KEYS.reduce((memo, shortNameKey) => {
      const shortName = shortNameKey;
      const realValue = this.props[shortName];
      if (realValue) {
        if (isTValParam(realValue)) {
          return TTYPE.reduce((memoResult, size) => {
            const cssVariableName =
              size !== 'lg'
                ? (`${this.var_prefix}${shortName}-${size}` as const)
                : (`${this.var_prefix}${shortName}` as const);
            const v1 = realValue[size];
            if (v1) {
              memoResult[cssVariableName] = v1;
            }
            return memoResult;
          }, memo);
        } else {
          const cssVariableName = `${this.var_prefix}${shortName}`;
          memo[cssVariableName] = realValue;
        }
      }
      return memo;
    }, {} as Record<string, string>);
  }
}

export interface IPropMap {
  style: string;
  cssVar?: boolean;
  convert?: (arg0?: TVal<string>) => TVal<string> | undefined;
}
export type TPropsMapping = Record<string, IPropMap>;
type ValueOf<T> = T[keyof T];
/*
 * This CssBuilder handles css classes generated through generateStyleVariations of _media_style_generator.scss
 */
export class CssBuilderGeneric<TProps> {
  private readonly styles$: Record<string, string>;
  private readonly props: TProps;
  private readonly propsMap: TPropsMapping;
  private readonly defaults?: Partial<TProps>;

  constructor(
    styles: Record<string, string>,
    props: TProps,
    propsMap: TPropsMapping,
    defaults?: Partial<TProps>
  ) {
    this.styles$ = styles;
    this.props = props;
    this.propsMap = propsMap;
    this.defaults = defaults;
  }

  propClassName(propKey: string, size?: E_SIZE) {
    const key = propKey as keyof TProps;
    const cssProp = this.propsMap[propKey];
    let propValue;
    if (size && isTValParam(this.props[key])) {
      const propTValue = this.props[key] as { [k in TType]?: ValueOf<TProps> };
      propValue = propTValue[size];
    } else {
      propValue = this.props[key];
    }
    const cssName = cssProp.cssVar
      ? `${cssProp.style}`
      : `${cssProp.style}-${propValue}`;
    const returnValue =
      !size || size === E_SIZE.LG ? cssName : `${cssName}-${size}`;
    return returnValue;
  }

  propValue(propKey: string) {
    const key = propKey as keyof TProps;
    const realValue = this.props[key];
    const cssProp = this.propsMap[propKey];
    if (cssProp.convert) {
      return cssProp.convert(realValue as string);
    }
    return realValue;
  }

  get classNames(): string[] {
    const propsKeys = Object.keys(this.propsMap);
    return propsKeys.reduce((memo: string[], propKey: string) => {
      const key = propKey as keyof TProps;
      const propValue = this.props[key];
      if (propValue !== undefined && propValue !== null) {
        if (isTValParam(propValue)) {
          const tValue = propValue as { [k in TType]?: ValueOf<TProps> };
          return TTYPE.reduce((memoResult, size) => {
            if (tValue[size] === undefined || tValue[size] === null) {
              return memoResult;
            }
            const cssName = this.propClassName(propKey, size);
            const style = this.styles$[cssName];
            if (style) {
              memoResult.push(style);
            }
            return memoResult;
          }, memo);
        } else {
          const cssName = this.propClassName(propKey);
          if (
            (this.defaults &&
              this.defaults[key] &&
              this.defaults[key] !== propValue) ||
            !this.defaults ||
            !this.defaults[key]
          ) {
            const style = this.styles$[cssName];
            if (style) {
              memo.push(style);
            }
          }
        }
      }
      return memo;
    }, [] as string[]);
  }

  get cssVars(): Record<string, string> {
    const propsKeys = Object.keys(this.propsMap);
    return propsKeys.reduce((memo, propName) => {
      if (!this.propsMap[propName].cssVar) return memo;
      const cssName = this.propsMap[propName].style;
      const realValue = this.propValue(propName);
      if (realValue !== undefined && realValue !== null) {
        if (isTValParam(realValue)) {
          return TTYPE.reduce((memoResult, size) => {
            const cssVariableName =
              size !== E_SIZE.LG ? `--${cssName}-${size}` : `--${cssName}`;
            const value = realValue[size];
            if (value) {
              memoResult[cssVariableName] = value;
            }
            return memoResult;
          }, memo);
        } else {
          const cssVariableName = `--${cssName}`;
          memo[cssVariableName] = realValue as string;
        }
      }
      return memo;
    }, {} as Record<string, string>);
  }
}

const rxBrm = /^([0-9.]+)brm$/;
function brm2pxValue(val: string, brm = 16): string {
  return val
    .split(' ')
    .map((item) => {
      if (rxBrm.exec(item)) {
        const v = parseFloat(RegExp.$1);
        if (Number.isNaN(v)) {
          return item;
        }
        return `${v * brm}px`;
      }
      return item;
    })
    .join(' ');
}

export function convertor(
  val: TVal<string> | undefined,
  apply: (val: string) => string
): TVal<string> | undefined {
  if (!val) {
    return undefined;
  }
  if (typeof val !== 'string' && typeof val !== 'object') {
    return val;
  }
  if (typeof val === 'string') {
    return apply(val);
  }

  const ret: TVal<string> = {};
  TTYPE.forEach((size) => {
    const v = val[size];
    if (v === undefined) {
      return;
    }
    ret[size] = apply(v);
  });
  return ret;
}

export function brm2px(val?: TVal<string>, brm = 16): TVal<string> | undefined {
  return convertor(val, (v) => brm2pxValue(v, brm));
}

export function getClassNameByValue<TPrefix extends string, T extends string>(
  prefix: TPrefix,
  val: TVal<T> | undefined,
  styles: Partial<
    Record<`${TPrefix}-${T}` | `${TPrefix}-${T}-${TSize}`, string>
  >
): string[] {
  const ret: string[] = [];
  if (!val) {
    return ret;
  }
  if (!isTValParam(val)) {
    const name = `${prefix}-${val}` as const;
    const styleValue = styles[name];
    if (styleValue) {
      ret.push(styleValue);
    }
  } else {
    TTYPE.forEach((size) => {
      const v = val[size];
      if (!v) {
        return;
      }
      const name =
        size === E_SIZE.LG
          ? (`${prefix}-${v}` as const)
          : (`${prefix}-${v}-${size}` as const);
      const styleValue = styles[name];
      if (styleValue) {
        ret.push(styleValue);
      }
    });
  }
  return ret;
}
