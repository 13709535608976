import {
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
  ObservableQuery,
  ObservableSubscription,
  OperationVariables,
  QueryOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { equal } from '@wry/equality';
import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from 'mobx';

import { TOperationContext } from './config/batch-config';
import { GraphqlClient } from './data-client';

export interface IQueryModelOpts<TVariables>
  extends Omit<QueryOptions<TVariables>, 'query' | 'context'> {
  lazy?: boolean;
  client: GraphqlClient;
  context?: TOperationContext;
}

export abstract class BaseQueryModel<
  TData,
  TVariables extends OperationVariables = Record<string, unknown>
> {
  private observableQuery: ObservableQuery<TData, TVariables> | undefined;
  private queryResult: ApolloQueryResult<TData | undefined> | undefined;
  private prevQueryResult: ApolloQueryResult<TData | undefined> | undefined;
  private client: GraphqlClient;
  private opts: IQueryModelOpts<TVariables> | undefined;
  private cacheSubscription: ObservableSubscription | undefined;
  protected abstract get query(): TypedDocumentNode<TData, TVariables>;

  private variablesDisposer: IReactionDisposer | undefined;

  /** Observable variables handle. Change it to refetch result */
  public variables?: TVariables;
  private variablesRequested?: TVariables;

  constructor(opts: IQueryModelOpts<TVariables>) {
    this.opts = opts;
    this.client = opts.client;
    this.variables = opts?.variables;
    this.startObservation();

    if (!this.opts?.lazy) {
      this.watchQuery();
    }
  }

  /**
   * Explicity bound to `this` so fetchMore can be destructured
   * @example
   * const { fetchMore } = useQuery()
   */
  fetchMore = (opts: OperationVariables) => {
    return this.observableQuery?.fetchMore(opts);
  };

  /**
   * Explicity bound to `this` so refetch can be destructured
   * @example
   * const { refetch } = useQuery()
   */
  refetch = (opts?: Partial<TVariables>) => {
    return this.observableQuery?.refetch(opts);
  };

  private startObservation() {
    makeObservable<this, 'queryResult' | 'updateQueryResult' | 'onQueryError'>(
      this,
      {
        queryResult: observable.ref,
        updateQueryResult: action,
        onQueryError: action,
        variables: observable.struct,
        setVariables: action,
        refetch: action,
      }
    );
    onBecomeObserved(this, 'queryResult', () => {
      this.subscribeToCache();
      this.subscribeToVariables();
    });
    onBecomeUnobserved(this, 'queryResult', () => {
      this.unsubscribeFromCache();
      this.unsubscribeFromVariables();
    });
  }

  setVariables(variables: TVariables | undefined) {
    this.variables = variables;
  }

  private watchQuery() {
    this.observableQuery = this.client.watchQuery<TData, TVariables>({
      ...this.opts,
      query: this.query,
    });
    this.variablesRequested = this.variables;
    this.subscribeToCache();
  }

  private subscribeToVariables = () => {
    this.variablesDisposer?.();
    this.variablesDisposer = autorun(
      () => {
        if (!equal(this.variables, this.variablesRequested)) {
          this.refetch(this.variables);
          this.variablesRequested = this.variables;
        }
      },
      { name: 'variablesUpdate' }
    );
  };

  private unsubscribeFromVariables = () => {
    this.variablesDisposer?.();
    this.variablesDisposer = undefined;
  };

  private subscribeToCache = () => {
    if (this.cacheSubscription) return;

    this.updateQueryResult();
    this.cacheSubscription = this.observableQuery?.subscribe(
      this.updateQueryResult,
      this.onQueryError
    );
  };

  private unsubscribeFromCache = () => {
    this.cacheSubscription?.unsubscribe();
    this.cacheSubscription = undefined;
  };

  private updateQueryResult = () => {
    const next = this.observableQuery?.getCurrentResult();
    /** Make sure we're not attempting to reset observable property using similar results **/
    if (equal(this.prevQueryResult, next)) return;
    this.prevQueryResult = next;
    this.queryResult = next;
  };

  private onQueryError = (error: Error) => {
    if (!this.observableQuery) return;

    // Code is taken from Apollo useQuery
    // https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useQuery.ts#L185
    const last = this.observableQuery['last'];
    this.cacheSubscription?.unsubscribe();
    // Unfortunately, if `lastError` is set in the current
    // `observableQuery` when the subscription is re-created,
    // the subscription will immediately receive the error, which will
    // cause it to terminate again. To avoid this, we first clear
    // the last error/result from the `observableQuery` before re-starting
    // the subscription, and restore it afterwards (so the subscription
    // has a chance to stay open).
    try {
      this.observableQuery.resetLastResults();
      this.cacheSubscription = this.observableQuery.subscribe(
        this.updateQueryResult,
        this.onQueryError
      );
    } finally {
      this.observableQuery['last'] = last;
    }

    if (!Object.prototype.hasOwnProperty.call(error, 'graphQLErrors')) {
      // The error is not a GraphQL error
      throw error;
    }

    this.prevQueryResult = this.queryResult;

    if (
      (this.prevQueryResult && this.prevQueryResult.loading) ||
      !equal(error, this.prevQueryResult?.error)
    ) {
      this.queryResult = {
        data: this.prevQueryResult?.data,
        error: error as ApolloError,
        loading: false,
        networkStatus: NetworkStatus.error,
      };
    }
  };

  /** This method to reset the queryResult for query */
  resetQuery = () => {
    this.queryResult = undefined;
    this.unsubscribeFromCache();
  };

  /** This method to check request is in progress or completed. */
  get isLoading(): boolean {
    return Boolean(this.queryResult?.loading);
  }

  /** This method to check request is rejected or not. */
  get isRejected(): boolean {
    return Boolean(this.queryResult?.error || this.queryResult?.errors);
  }

  get queryErrors(): GraphQLErrors | ApolloError | undefined {
    return this.queryResult?.errors || this.queryResult?.error;
  }

  /** NOTE: This method returns the `data` key from graphql result object */
  get data(): TData | undefined {
    return this.queryResult?.data;
  }

  subscribe = () => {
    if (!this.opts?.lazy) return;
    this.watchQuery();
  };

  /** NOTE: This method is for SSR/SSG pages `getStaticProps`, `getStaticPaths`, `getServerSideProps` etc. */
  load = (): Promise<ApolloQueryResult<TData>> | undefined => {
    return this.client
      .query({
        ...this.opts,
        query: this.query,
      })
      .then((res) => {
        this.queryResult = res;
        return res;
      });
  };
}
